// MW WP FORM Wordpressプラグインを使用
// <dl>
// <div><dt><label for="contact-name">お名前<label><dd>[mwform_text id="contact-name" name="お名前" size="60" placeholder=""]</dd></div>
// <div><dt><label for="contact-email">メールアドレス<label></dt><dd>[mwform_email id="contact-email" name="メールアドレス" size="60" placeholder=""]</dd></div>
// <div><dt><label for="contact-message">メッセージ<label></dt><dd>[mwform_textarea id="contact-message" name="メッセージ" cols="50" rows="5" placeholder=""]</dd></div>
// </dl>
// [mwform_submitButton name="contact-submit" confirm_value="確認画面へ" submit_value="送信する"]


form{

  b{
    font-family: sans-serif;
    font-weight: 900;
    font-size: rem-calc(14);
    color: $magenta;
  }

  textarea,
  input[type='text'],
  input[type='number'],
  input[type='email']{
    margin: 0;
    margin-right: 1em;
    padding: 0.5em 1em;
    min-width: 16em;
    max-width: 100%; min-height: 100%;
    border: 3px solid $light-gray;
    border-radius: 0.5em;
    line-height: 1.5;
    font-size: 1rem;
    font-family: sans-serif !important;
    &:focus{
      background-color: $pale-gray;
      border-color: $pale-gray;
      border-radius: 0;
    }
  }

  input[name='user_name'],
  input[name='user_mail']{
    display: block;
    margin-bottom: 0.5em;
  }
  input[name='user_mail']{
    width: 32em !important;
  }


  textarea{
    height: 10em;
    @include breakpoint(small down){
      height: 5em;
    };
  }

  select{
    position: relative;
    appearance: none;
    padding: 0.75em 3em 0.75em 1em;
    border: 3px solid $light-gray;
    border-radius: 0.5em;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23626E6C'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 12px) calc(50% + 3px);
    background-repeat: no-repeat;
    font-size: font-size(base);
    &:focus{
      border-color: $white;
      border-radius: 0;
    }
    & + br + input[type='text']{
      margin-top: -3px;
    }
  }

  input[type='button'],
  input[type='submit']{
    @extend .button;
    appearance: none;
    margin-top: 1em;
    margin-bottom: 2em;
    @include breakpoint(small down){
      margin-top: 0;
    };
  }

  input[name='resign'],
  input[name='submitBack']{
    background-color: $gray-green !important;
    box-shadow: 0 5px 12px rgba($gray-green,0.4) !important;
  }

  .error{
    display: block;
    color: $magenta !important;
  }

  .vertical-item{
    margin-top: 1em;
  }
}
