.logo-do-it--wide{
  @extend %en-condensed-font;
  padding-top: 0.25em;
  padding-bottom: 0.5em;
}

p{
  text-align: justify;
  .has-3-columns &,
  .has-4-columns &,
  .has-5-columns &{
    text-align: left;
  }
}
