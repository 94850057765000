.wp-block-columns{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wp-block-column{
  margin-bottom: 1em;
  @include breakpoint(large up){
    .has-5-columns &{ width: calc(20% - 0.5em); }
  };
  @include breakpoint(medium up){
    .has-2-columns &{ width: calc(50% - 1em); }
    .has-3-columns &{ width: calc(33.333% - 0.5em); }
    .has-4-columns &{ width: calc(25% - 0.5em); }
  };
  @include breakpoint(medium only){
    .has-5-columns &{ width: calc(33.333% - 0.5em); }
  };
  @include breakpoint(small down){
    .has-4-columns &,
    .has-5-columns &{ width: calc(50% - 0.5em); }
    .has-6-columns &{ width: calc(33.333% - 0.5em); }
    &{ width: 100%; }
  };
}
.has-5-columns{
  @include breakpoint(medium only){
    justify-content: flex-start;
  };
}

.wp-block-separator{
  max-width: 4em;
  margin: 4em auto;
}

//
.wp-block-image{
  margin-top: 1em;
}
.aligncenter{ text-align: center; }
.alignleft{ text-align: left; }
.alignright{ text-align: right; }

.clearleft{ clear: left; }
.clearright{ clear: right; }
.clearboth{ clear: both; }

@include breakpoint(medium up){
  figure.alignleft,
  figure.alignright{ max-width: calc(50% - 1em); }
  figure.alignleft { float: left; margin-right: 1em; }
  figure.alignright{ float: right; margin-left: 1em; }
}
@include breakpoint(small down){
  figure.alignleft { margin-bottom: 1em; }
  figure.alignright{ margin-bottom: 1em; }
}
