
.logo-do-it{
  @extend %en-condensed-font;
  abbr{
    letter-spacing: 1px;
  }
}
.site-title{
  &{
    position: relative;
    margin: 0 0 1.25em;
  }
  &__logo{
    font-size: 60px;
    line-height: 50px;
    @include breakpoint(small down){
      font-size: 50px;
      line-height: 40px;
    };
  }
  &__word-wrap{
    @extend %en-condensed-font;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
  }
  &__word{
    margin: 0;
    letter-spacing: 0.125em;
    line-height: 1.25;
    &::first-letter{ color: $emerald-green; }
    @include breakpoint(small down){
      font-size: 12px;
    };
  }
  dfn{ font-style: normal; }
  a{ color: $gray; }
  a:before{ @extend %anchor-wrap; }
}

.site-header{
  position: absolute; z-index: 256;
  min-width: 186px;
  padding: 1.5em 0 0 1.25em;
  background-color: $white;
  color: $gray;
  @include breakpoint(small down){
    min-width: 8.5em;
    padding-top: 1.25em;
    padding-right: 0;
  };
  .ss-icon.ss-community{
    @extend %ja-font;
    &:before{ font-family: $icon-font; }
  }
  &__button{
    display: block;
  }
}

.site-header__button,
.donation-nav__lists{
  margin-left: -1.25em;
  padding-left: 1.25em;
  border-top: 2px solid $emerald-green;
  background-color: $pale-green;
  font-size: font-size(base);
  line-height: calc(3em - 3px);
  &, li{ color: $emerald-green-text; }
}

.donation-nav__lists{
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed; z-index: 256;
  left: 0; bottom: 0;
  margin: 0; padding: 0;
  list-style-type: none;
  li{
    position: relative;
    padding-left: 1em;
    padding-right: 1.25em;
    &:before{ margin-right: 0.5em; }
  }
  a{ @extend %ja-font; }
  a:before{ @extend %anchor-wrap; }
}
