h1, h2, h3, h4, h5, h6{
  margin-top: line-unit(0.5);
  margin-bottom: line-unit(1);
}

p, video{
  margin-top: line-unit(0.5);
  margin-bottom: line-unit(1);
}

section{
  clear: both;
}
