// ページ共通フッター
#join{
  margin: 0;
}

.section-part-in{
  position: relative;
  padding: 2em;
  color: $emerald-green-text;
  @include breakpoint(large up){
    padding: 8em 4em 4em;
  };
  @include breakpoint(medium only){
    padding: 2em;
  };
  @include breakpoint(medium down){
    img{ height: 100%; }
  };
  @include breakpoint(small down){
    flex-direction: column;
  };
  h3{
    @extend .has-medium-font-size;
   }
  .wp-block-column:last-of-type,
  .wp-block-column:first-of-type{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em;
    background-color: rgba($white, 0.93);
    @include breakpoint(medium only){
      width: calc(50% - 1em);
    };
  }
  .wp-block-column:nth-of-type(2){ width: 0; }
  .wp-block-column{
    p{ flex: 1 1 auto; }
    h3, .wp-block-button{ flex: 0 0 auto; }
  }
}
.section-news-letter{
  background-color: $emerald-green;
  color: $white;
  .wp-block-column{
    @include breakpoint(large up){ width: 25%; };
    @include breakpoint(medium only){ width: 12.5%; };
    @include breakpoint(small down){ width: 100%; };
  }
  .wp-block-column:nth-of-type(2){
    padding: 2em;
    @include breakpoint(large up){ width: 50%; };
    @include breakpoint(medium only){ width: 75%; };
    @include breakpoint(small down){ width: 100%; };
  }
  h2.ss-icon{
    @extend %en-condensed-font;
    &:before{
      content:"✉";
      font-family: $icon-font;
      vertical-align: baseline;
    }
    @include breakpoint(large up){ font-size: rem-calc(60); };
    @include breakpoint(medium only){ font-size: 6vw; };
    @include breakpoint(small down){ font-size: 12vw; };
  }
}
%background-image{
  position: absolute; z-index: -1;
  top: 0; bottom: 0;
  left: 0; right: 0;
}
.bg-image{
  &,
  &-center{
    position: relative;
    overflow: hidden;
  }
  .wp-block-media-text__media img,
  &-center .wp-block-column:nth-of-type(2) img{
    @extend %background-image;
    width: 100%;
  }
  .wp-block-media-text__media img{
    @include breakpoint(medium only){
      height: 100%;
    }
    @include breakpoint(small down){
      position: static;
      height: 100%;
    }
  }
  &-center .wp-block-column:nth-of-type(2) img{
    height: 100%;
  }
  .wp-block-media-text__content{
    padding: 2em;
    @include breakpoint(large up){ padding: 4em; };
    @include breakpoint(medium up){ width: 50%; };
  }
  .wp-block-media-text__content{ margin-left: auto; }
  &.has-media-on-the-right .wp-block-media-text__content{ margin-left: 0; margin-right: auto; }
  .wp-block-button,
  &-center .wp-block-button{ margin-top: 2em; }
  .site-contents &.has-light-green-cyan-background-color,
  .site-contents &.has-pale-cyan-blue-background-color,
  .site-contents &.has-vivid-cyan-blue-background-color{
    background-color: transparent;
    color: $white;
  }
}
.has-light-green-cyan-background-color .wp-block-media-text__content{ background-color: rgba($emerald-green, 0.93); }
.has-pale-cyan-blue-background-color .wp-block-media-text__content{ background-color: rgba($cyan, 0.93); }
.has-vivid-cyan-blue-background-color .wp-block-media-text__content{ background-color: rgba($blue, 0.93); }

.to-top-button{
  position: relative;
  display: block;
  text-align: center;
  font-size: rem-calc(33);
  a{ line-height: 1.5em; }
  .site-contents & a:before{ margin-right: 0; }
  .visually-hidden{ top: 0; left: 0; }
}
