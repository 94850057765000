.page-contents{

  .has-text-align-center{
    text-align: center;
  }
  .has-text-align-right{
    text-align: right;
  }
  .has-text-align-left{
    text-align: left;
  }
  .aligncenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .alignright{
    float: right;
  }
  .alignleft{
    float: left;
  }
  .alignwide{
    width: (100% + 100% / 4 * 2);
    margin-left: calc(-100% / 4);
    margin-right: calc(-100% / 4);
    @include breakpoint(medium only){
      width: (100% + 100% / 2 * 1);
      margin-left: calc(-100% / 2 * 0.5);
      margin-right: calc(-100% / 2 * 0.5);
    }
    @include breakpoint(small down){
      width: calc(100% + #{rem-calc(32)});
      margin-left: #{rem-calc(-16)};
      margin-right: #{rem-calc(-16)};
    };
  }
  .alignfull{
    @extend .alignfull;
    .alignwide{
      width: 100%;
      max-width: rem-calc(1216);
      margin: auto;
    }
  }
  .wp-block-image img {
    max-width: 100%;
    height: auto;
  }
}
