.page-anchor{
  position: absolute;
  top: -4em;
}

.page-index{
  &{
    transition: top 0.5s ease(out-quint);
    position: relative;
    top: 0; right: -1em;
    .page-header-out &{
      position: fixed; z-index: 64;
      top: 5em; right: 0;
      .wp-logged-in &{ top: calc(4em + 45px); }
    }
  }
  &__title{
    margin: 0;
    font-size: font-size(base);
  }
  &__lists{
    @extend .no-bullet;
    display: none;
    position: absolute; z-index: 64;
    top: 100%; right: 0;
    width: 24em;
    margin: 0; padding: 0;//1em;
    background-color: $white;
    box-shadow:0px 0px 50px -8px rgba($black, 0.5);
    &.open{
      display: block;
    }
    li:not(:last-child){
      border-bottom: 1px solid $pale-gray;
    }
    a{
      display: flex;
      align-items: center;
      padding: 1em;
      line-height: 1.25;
    }
    a:hover{
      background-color: $emerald-green;
      color: $white;
    }
    a:before{
      content: '';
      margin-top: 0.5em;
      margin-right: 1em;
      font-family: $icon-font;
    }
  }
  &__close{
    position: absolute;
    bottom: 100%; right: 0;
  }
  &__open-button,
  &__close-button{
    cursor: pointer;
    appearance: none;
    padding: 0.625em 1em 0.5em;;
    background-color: $white;
    border: none;
    white-space: nowrap;
    font-size: rem-calc(14);
    color: $emerald-green;
    i{
      margin-left: 0.25em;
      vertical-align: -0.125em;
    }
  }
}
