%magenta-tree{
  background: $white url('../images/illust-tree.svg') no-repeat;
  background-size: auto 125%;
  background-position: center;
}

.section-3programs{
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 500px;
  padding: 2em;
  @include breakpoint(medium up){
    height: 500px;
  };
  @include breakpoint(small down){
    flex-wrap: nowrap;
    flex-direction: column;
  };
  .wp-block-separator ~ &{
    @extend %magenta-tree;
    margin-bottom: -4em;
  }
  .wp-block-column h3{
    color: $emerald-green-text;
  }
  & > .wp-block-column{
    position: relative;
    max-width: 392px;
    background-color: $light-green;
    @include breakpoint(medium up){
      &:nth-of-type(2){ order: 3; }
      &:nth-of-type(3){ order: 2; align-self: flex-end; }
    };
    @include breakpoint(medium only){
      max-width: 33%;
      align-self: flex-start;
    };
    @include breakpoint(small down){
      width: 100%;
      margin-bottom: 1em;
    };
  }
  .wp-block-columns{
    flex-direction: row;
    background-color: $pale-green;
    .wp-block-column{
      position: relative;
      flex: 1 1 auto;
      width: calc(100% - 10em);
      padding: 0.5em 1em;
      p{
        margin: 2em 0 1em;
        line-height: 1.25;
      }
      p:first-child{
        display: inline-block;
        position: absolute;
        top: 0; left: 0;
        margin: 0;
        padding: 0.25em 1em;
        white-space: nowrap;
      }
    }
    .wp-block-column:last-of-type{
      flex: 0 0 auto;
      align-self: flex-end;
      width: inherit;
      padding-left: 0;
      padding-bottom: 1em;
    }
  }
  @include breakpoint(medium only){
    .wp-block-column h3:before{ margin-top: 0.25em; }
    .wp-block-column h3 strong{ clear: left; }
    .wp-block-button{ max-width: 5em; margin: 0; }
  };
}

.section-3programs h3,
.headline-program--scholar,
.headline-program--pal,
.headline-program--school
{
  @extend %en-font-bold;
  &{
    margin: 0;
    padding: 0.75em 0.5em;
    border: none;
    font-size: rem-calc(27);
  }
  strong{
    @extend %ja-font;
    display: block;
    margin-top: 0.25em;
    font-size: rem-calc(18);
  }
  &:before{
    float: left;
    margin-right: 0.375em;
    margin-top: 0.05em;
    padding: 0.25em;
    font-family: $icon-font;
    font-weight: normal;
    font-size: rem-calc(38);
  }
}
.headline-program--scholar:before{ content: ''; }
.headline-program--pal:before{ content: ''; }
.headline-program--school:before{ content: '👍'; }

.section-3program{
  .wp-block-column:first-of-type{
    @include breakpoint(medium up){
      svg{
        width: calc(100% + 24vw);
        height: 64vw;
        margin-left: -16vw;
        margin-right: -8vw;
      }
    };
  }
  .wp-block-column:last-of-type{
    position: relative; z-index: 1;
    padding: 1.5em;
    background-color: $white;
    @include breakpoint(medium up){
      padding: 0; padding-left: 1em;
    };
  }
  h3{
    margin-top: 1em;
    padding: 0.5em 0;
    font-size: font-size(xxlarge);
    color: $emerald-green-text;
    &:before{
      display: block;
      line-height: 1em;
      margin-right: 0.125em;
    }
    & > a:before{ @extend %anchor-wrap; }
    strong{
      @extend %ja-font;
      font-size: font-size(base);
    }
  }
  p{
    margin-bottom: 0;
    font-size: font-size(small);
  }
  .wp-block-button{
    text-align: right;
  }
}

.js-wpv-view-layout{
  @include breakpoint(medium up){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .thumb-post{
      width: calc(50% - 1em);
    }
    .thumb-post:first-of-type:last-of-type{
      width: 100%;
    }
  }
}
