.page-contents,
.editor-writing-flow{

  h2:not(.visually-hidden){
    position: relative;
    margin-top: 3em;
    padding-top: 3em;
    line-height: line-unit(1.5);
    font-size: font-size(xlarge);
    color: $emerald-green-text;
    &:before{
      content: '';
      position: absolute;
      top: 0; bottom: 0;
      right: calc(100% + 1em);
      width: 50vw;
      border-right: 1vw solid $emerald-green-text;
      background-color: $pale-gray;
    }
  }
  .wp-block-columns h2{ margin-top: 0; }

  h3{
    //@extend .has-medium-font-size;
    position: relative;
    margin-top: 2em;
    padding: calc(1em - 1px) 0.5em;
    border-top: 1px solid $emerald-green-text;
    border-bottom: 1px solid $emerald-green-text;
    line-height: line-unit(1.25);
    font-size: font-size(large);
  }

  h4{
    display: inline-block;
    position: relative;
    margin-top: 2em;
    padding-top: 0.5em;
    letter-spacing: 0.125em;
    line-height: line-unit(1);
    font-size: font-size(base);
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0; right: 0;
      height: 0.125em;
      border-radius: (0.125em / 2);
      background-color: $emerald-green;
    }
    &:after{
      content: '\A';
      white-space: pre;
    }
  }

  ol.flow{
    padding-left: 0;
    li{
      margin-bottom: 2em;
      padding: 1em;
      border: 2px solid $yellow;
      border-radius: 0.5em;
      line-height: 1.5;
    }
    li:not(:last-child):after{
      @extend %en-font-bold;
      content: 'V';
      display: block;
      position: absolute;
      top: 100%; left: 50px;
      width: 0; height: 0.5em;
      padding-top: 0.75em;
      border-left: 4px solid;
      border-bottom: 6px solid;
      line-height: 1;
      text-indent: -0.375em;
      font-size: 30px;
      color: $yellow;
    }
  }
  ol{
    &:not([start]){
      counter-reset: list-count;
    }
    &{
      margin-left: 0;
      list-style-type: none;
    }
    li{
      display: flex;
      position: relative;
      margin-bottom: 1em;
    }
    li:before{
      @extend %en-font;
      position: relative; top: -0.125em;
      counter-increment: list-count;
      content: attr(start)+counter(list-count);
      margin-right: 0.5em;
      font-size: 175%;
      color: $yellow;
    }
  }

  iframe{
    overflow: hidden;
    width: 100%;
    max-width: 100%;
  }

  .wp-block-button{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .wp-block-columns{
  }
  .has-3-columns .trim-image{
    position: relative;
    height: 0;
    padding-top: 40%;
    img{
      position: absolute;
      width: 100%; height: 100%;
    }
  }

  p, li, dd{
    & > a{ text-decoration: underline; }
  }
}


.button{
  transition: transform 0.5s ease(out-quint);
  transform-origin: center;
  transform: scale(1);
  &:focus,
  &:hover{ transform: scale(1.05); }
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 1em;
  border: none;
  border-radius: 6px;
  background-color: $emerald-green;
  box-shadow: 0 5px 12px rgba($emerald-green-text,0.4);
  text-align: center;
  text-decoration: none;
  white-space: normal;
  overflow-wrap: break-word;
  line-height: 1.25;
  font-size: 1em;
  color: $white;
  em{ font-size: rem-calc(12); font-style: normal; }
}

// エディタ専用
.editor-writing-flow{
  //エディタの作業領域を広くする
  .wp-block{
    max-width: 1028px;
  }
  @media (min-width: 600px) {
    .editor-block-list__layout .editor-block-list__block[data-align=full] {
      max-width: none;
    }
  }
  p{
    line-height: 1.5;
  }
}

// ページ共通コンテンツ
.site-contents,
.editor-writing-flow{

  .btn,
  .button,
  .wp-block-button__link {
    @extend .button;
  }

  .has-vivid-cyan-blue-background-color{
    background-color: $cyan;
    box-shadow: 0 5px 12px rgba($blue,0.4);
  }

  .has-pale-pink-background-color,
  .has-vivid-red-background-color,
  .has-luminous-vivid-orange-background-color,
  .has-luminous-vivid-amber-background-color,
  .has-light-green-cyan-background-color,
  .has-vivid-green-cyan-background-color,
  .has-pale-cyan-blue-background-color,
  .has-vivid-cyan-blue-background-color,
  .has-very-light-gray-background-color,
  .has-cyan-bluish-gray-background-color,
  .has-very-dark-gray-background-color {
    a:focus{ outline: $white auto 5px; }
    .btn,
    .button,
    .wp-block-button__link {
      background-color: $pale-green;
      color: $emerald-green-text;
    }
  }

}
