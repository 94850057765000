$yakuhanjps-eot-use: false;
$yakuhanjps-cdn-use: true;
@import "node_modules/yakuhanjp/src/scss/yakuhanjp_s.scss";

$ja-font: $yakuhanjps-font-family, "TBゴシック M", "TBGothic M";
$en-font: urw-din;
$en-condensed-font: futura-pt-condensed;
$icon-font: "SSCommunity";

%ja-font{
  font-family: $ja-font, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

%en-font-base{
  font-family: $en-font, sans-serif;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

%en-font,
%en-font-regular{ @extend %en-font-base; font-weight: 400; }
%en-font-medium { @extend %en-font-base; font-weight: 500; }
%en-font-bold   { @extend %en-font-base; font-weight: 700; }
%en-font-xbold  { @extend %en-font-base; font-weight: 800; }

%en-condensed-font-base{
  font-family: $en-condensed-font, sans-serif;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

%en-condensed-font-regular{ @extend %en-condensed-font-base; font-weight: 400; }
%en-condensed-font,
%en-condensed-font-medium { @extend %en-condensed-font-base; font-weight: 500; }
%en-condensed-font-bold   { @extend %en-condensed-font-base; font-weight: 700; }
%en-condensed-font-xbold  { @extend %en-condensed-font-base; font-weight: 800; }

//sizeing
$window-min: 320px;
$window-max: 1280px;
$base-min: 21px; $base-max: 24px;

$font-size: (
	 xsmall: linear-interpolation(($window-min: 10px, $window-max: 12px)),
	  small: linear-interpolation(($window-min: 12px, $window-max: 14px)),
     base: linear-interpolation(($window-min: 14px, $window-max: 16px)),
      mid: linear-interpolation(($window-min: 15px, $window-max: 18px)),
	  large: linear-interpolation(($window-min: 16px, $window-max: 22px)),
	 xlarge: linear-interpolation(($window-min: 22px, $window-max: 28px)),
	xxlarge: linear-interpolation(($window-min: 28px, $window-max: 34px)),
);
$line-unit: (
  0.5 : linear-interpolation(($window-min: $base-min * 0.5 , $window-max: $base-max * 0.5)),
  0.75: linear-interpolation(($window-min: $base-min * 0.75, $window-max: $base-max * 0.75)),
  1   : linear-interpolation(($window-min: $base-min * 1   , $window-max: $base-max * 1)),
  1.25: linear-interpolation(($window-min: $base-min * 1.25, $window-max: $base-max * 1.25)),
  1.5 : linear-interpolation(($window-min: $base-min * 1.5 , $window-max: $base-max * 1.5)),
  1.75: linear-interpolation(($window-min: $base-min * 1.75, $window-max: $base-max * 1.75)),
  2   : linear-interpolation(($window-min: $base-min * 2   , $window-max: $base-max * 2)),
  3   : linear-interpolation(($window-min: $base-min * 3   , $window-max: $base-max * 3)),
);

@function font-size($key) {
  @if map-has-key($font-size, $key)
  { @return map-get($font-size, $key); }
  @warn "Unkown '#{$key}' in $font-size."; @return null;
}
@function line-unit($key) {
  @if map-has-key($line-unit, $key)
  { @return map-get($line-unit, $key); }
  @warn "Unkown '#{$key}' in $line-unit."; @return null;
}

.en{ @extend %en-font; font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0; }
body{ @extend %ja-font; }

h1, h2, h3, h4, h5, h6{ font-feature-settings: "pwid"; }

b, strong{ .en &, &.en{ @extend .en; } }
.en, p.en{ @extend .en; }

i{ font-feature-settings: "ital"; }

a{ text-decoration: none; }

//wordpress font settings
.has-small-font-size{
  line-height: line-unit(1);
  font-size: font-size(small);
}
.has-regular-font-size{
  line-height: line-unit(1);
  font-size: font-size(base);
}
.has-normal-font-size{
  line-height: line-unit(1);
  font-size: font-size(mid);
}
.has-medium-font-size{
  line-height: line-unit(1.25);
  font-size: font-size(large);
}
.has-large-font-size{
  line-height: line-unit(2);
  font-size: font-size(xlarge);
}
.has-huge-font-size{
  line-height: line-unit(2);
  font-size: font-size(xxlarge);
}
body, p, h1, h2, h3, h4, h5, h6{
  @extend .has-regular-font-size;
  font-size: font-size(base);
  line-height: line-unit(1);
}
