body{
  overflow-x: hidden;
}

.site-contents{
  position: relative; z-index: 1;
  margin-bottom: 32em;
  background-color: $white;
  @include breakpoint(small down){
    margin-bottom: $small-padding-bottom;
  };
  &__inner{
    margin: 0 auto;
    @include breakpoint(large up){ padding: 4em; };
    @include breakpoint(medium up){ max-width: 64em; };
    @include breakpoint(medium down){ padding: 0 2em 4em; };
    .home &{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  p:empty{ display: none; }
}

// エディタ除外
.page-contents{
  .visually-hidden{ @extend .visually-hidden; }
  .alignfull{
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
  }
}
