@charset "UTF-8";
.screen-reader-text,
.visually-hidden,
.page-contents .visually-hidden,
.skip-anchor:not(:focus) {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  padding: 0;
}

.skip-anchor {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

a, p {
  word-wrap: break-word;
}

main {
  display: block;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

video {
  width: 100%;
}

.no-bullet, .post__nav-lists, .page-index__lists, .footer-nav__lists, .global-nav__lists {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.page-contents .thumb-post__title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.section-3program h3 > a:before, .thumb-post a:before, .thumb-post a:after, .home-program-headline ~ .section-3programs:before, .home-cover a:before, .global-nav__menu-label a:before, .global-nav__menu-label span:before, .global-nav__list--home a:before, .global-nav__list--home span:before, .global-nav__list--lang a:before, .global-nav__list--lang span:before, .donation-nav__lists a:before, .site-title a:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.align-middle {
  align-items: center;
}

.image-cover, img {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.image-contain, img.contain, .contain img {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

img {
  overflow: hidden;
  vertical-align: bottom;
}
@keyframes blink-once {
  0%, 49%, 100% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
}
a {
  color: #199482;
}

button:hover,
a:hover {
  color: #2bb793;
}

body {
  color: #44504E;
}

.site-contents .has-pale-pink-background-color, .editor-writing-flow .has-pale-pink-background-color {
  background-color: #F5F0F4;
}
.site-contents .has-vivid-red-background-color, .editor-writing-flow .has-vivid-red-background-color {
  background-color: #d60808;
}
.site-contents .has-luminous-vivid-orange-background-color, .editor-writing-flow .has-luminous-vivid-orange-background-color {
  background-color: #932275;
}
.site-contents .has-luminous-vivid-amber-background-color, .editor-writing-flow .has-luminous-vivid-amber-background-color {
  background-color: #ea7900;
}
.site-contents .has-light-green-cyan-background-color, .editor-writing-flow .has-light-green-cyan-background-color {
  background-color: #ceeee7;
}
.site-contents .has-vivid-green-cyan-background-color, .editor-writing-flow .has-vivid-green-cyan-background-color {
  background-color: #09ae96;
}
.site-contents .has-pale-cyan-blue-background-color, .editor-writing-flow .has-pale-cyan-blue-background-color {
  background-color: #00A0D1;
}
.site-contents .has-vivid-cyan-blue-background-color, .editor-writing-flow .has-vivid-cyan-blue-background-color {
  background-color: #0080A7;
}
.site-contents .has-very-light-gray-background-color, .editor-writing-flow .has-very-light-gray-background-color {
  background-color: #fff;
}
.site-contents .has-cyan-bluish-gray-background-color, .editor-writing-flow .has-cyan-bluish-gray-background-color {
  background-color: #7B8987;
}
.site-contents .has-very-dark-gray-background-color, .editor-writing-flow .has-very-dark-gray-background-color {
  background-color: #626E6C;
}
.site-contents .has-pale-pink-color, .editor-writing-flow .has-pale-pink-color {
  color: #F5F0F4;
}
.site-contents .has-vivid-red-color, .editor-writing-flow .has-vivid-red-color {
  color: #d60808;
}
.site-contents .has-luminous-vivid-orange-color, .editor-writing-flow .has-luminous-vivid-orange-color {
  color: #932275;
}
.site-contents .has-luminous-vivid-amber-color, .editor-writing-flow .has-luminous-vivid-amber-color {
  color: #ea7900;
}
.site-contents .has-light-green-cyan-color, .editor-writing-flow .has-light-green-cyan-color {
  color: #2bb793;
}
.site-contents .has-vivid-green-cyan-color, .editor-writing-flow .has-vivid-green-cyan-color {
  color: #199482;
}
.site-contents .has-pale-cyan-blue-color, .editor-writing-flow .has-pale-cyan-blue-color {
  color: #00A0D1;
}
.site-contents .has-vivid-cyan-blue-color, .editor-writing-flow .has-vivid-cyan-blue-color {
  color: #0080A7;
}
.site-contents .has-very-light-gray-color, .editor-writing-flow .has-very-light-gray-color {
  color: #fff;
}
.site-contents .has-cyan-bluish-gray-color, .editor-writing-flow .has-cyan-bluish-gray-color {
  color: #7B8987;
}
.site-contents .has-very-dark-gray-color, .editor-writing-flow .has-very-dark-gray-color {
  color: #626E6C;
}

/*! Type: YakuHanJPs - Based on Noto Sans CJK JP */
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Thin.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Thin.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Light.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Light.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-DemiLight.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-DemiLight.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Regular.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Regular.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Medium.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Medium.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Bold.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Bold.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Black.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.3.1/dist/fonts/YakuHanJPs/YakuHanJPs-Black.woff") format("woff");
  unicode-range: U+3008-3011, U+3014-3015, U+ff08-ff09, U+ff3b, U+ff3d, U+ff5b, U+ff5d;
}
.section-3program h3 strong, .section-3programs h3 strong,
.headline-program--scholar strong,
.headline-program--pal strong,
.headline-program--school strong, .donation-nav__lists a, .site-header .ss-icon.ss-community, body {
  font-family: "YakuHanJPs", "TBゴシック M", "TBGothic M", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

.section-3programs h3,
.headline-program--scholar,
.headline-program--pal,
.headline-program--school, .page-contents ol.flow li:not(:last-child):after,
.editor-writing-flow ol.flow li:not(:last-child):after, .global-nav__menu-label a, .global-nav__menu-label span, .global-nav__list--home a, .global-nav__list--home span, .global-nav__list--lang a, .global-nav__list--lang span, .page-contents ol li:before,
.editor-writing-flow ol li:before, .footer-contact__copyright, .en, p.en, .en b, b.en, .en strong, strong.en {
  font-family: urw-din, sans-serif;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

.page-contents ol li:before,
.editor-writing-flow ol li:before, .footer-contact__copyright, .en, p.en, .en b, b.en, .en strong, strong.en {
  font-weight: 400;
}

.section-3programs h3,
.headline-program--scholar,
.headline-program--pal,
.headline-program--school, .page-contents ol.flow li:not(:last-child):after,
.editor-writing-flow ol.flow li:not(:last-child):after, .global-nav__menu-label a, .global-nav__menu-label span, .global-nav__list--home a, .global-nav__list--home span, .global-nav__list--lang a, .global-nav__list--lang span {
  font-weight: 700;
}

.logo-do-it--wide, .post__date, .thumb-post__date, .page-contents .home-event-news-headline h2:before, .page-title .en, .page-title .en b, .en .page-title b, .page-title .en strong, .en .page-title strong, .section-news-letter h2.ss-icon, .site-title__word-wrap, .logo-do-it {
  font-family: futura-pt-condensed, sans-serif;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

.logo-do-it--wide, .post__date, .thumb-post__date, .page-contents .home-event-news-headline h2:before, .page-title .en, .page-title .en b, .en .page-title b, .page-title .en strong, .en .page-title strong, .section-news-letter h2.ss-icon, .site-title__word-wrap, .logo-do-it {
  font-weight: 500;
}

.en, p.en, .en b, b.en, .en strong, strong.en {
  font-feature-settings: "tnum", "pwid", "liga" 0, "clig" 0;
}

h1, h2, h3, h4, h5, h6 {
  font-feature-settings: "pwid";
}

i {
  font-feature-settings: "ital";
}

a {
  text-decoration: none;
}

.has-small-font-size {
  line-height: calc(0.3125vw + 20px);
  font-size: calc(0.2083333333vw + 11.3333333333px);
}

.has-regular-font-size, body, p, h1, h2, h3, h4, h5, h6 {
  line-height: calc(0.3125vw + 20px);
  font-size: calc(0.2083333333vw + 13.3333333333px);
}

.has-normal-font-size, .page-contents .thumb-post__title, .footer-contact__title {
  line-height: calc(0.3125vw + 20px);
  font-size: calc(0.3125vw + 14px);
}

.has-medium-font-size, .thumb-post__copy, .section-part-in h3 {
  line-height: calc(0.390625vw + 25px);
  font-size: calc(0.625vw + 14px);
}

.has-large-font-size {
  line-height: calc(0.625vw + 40px);
  font-size: calc(0.625vw + 20px);
}

.has-huge-font-size, .post__title {
  line-height: calc(0.625vw + 40px);
  font-size: calc(0.625vw + 26px);
}

body, p, h1, h2, h3, h4, h5, h6 {
  font-size: calc(0.2083333333vw + 13.3333333333px);
  line-height: calc(0.3125vw + 20px);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: calc(0.15625vw + 10px);
  margin-bottom: calc(0.3125vw + 20px);
}

p, video {
  margin-top: calc(0.15625vw + 10px);
  margin-bottom: calc(0.3125vw + 20px);
}

section {
  clear: both;
}

.wp-block-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wp-block-column {
  margin-bottom: 1em;
}
@media print, screen and (min-width: 64em) {
  .has-5-columns .wp-block-column {
    width: calc(20% - 0.5em);
  }
}
@media print, screen and (min-width: 40em) {
  .has-2-columns .wp-block-column {
    width: calc(50% - 1em);
  }
  .has-3-columns .wp-block-column {
    width: calc(33.333% - 0.5em);
  }
  .has-4-columns .wp-block-column {
    width: calc(25% - 0.5em);
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .has-5-columns .wp-block-column {
    width: calc(33.333% - 0.5em);
  }
}
@media print, screen and (max-width: 39.99875em) {
  .has-4-columns .wp-block-column, .has-5-columns .wp-block-column {
    width: calc(50% - 0.5em);
  }
  .has-6-columns .wp-block-column {
    width: calc(33.333% - 0.5em);
  }
  .wp-block-column {
    width: 100%;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .has-5-columns {
    justify-content: flex-start;
  }
}

.wp-block-separator {
  max-width: 4em;
  margin: 4em auto;
}

.wp-block-image {
  margin-top: 1em;
}

.aligncenter {
  text-align: center;
}

.alignleft {
  text-align: left;
}

.alignright {
  text-align: right;
}

.clearleft {
  clear: left;
}

.clearright {
  clear: right;
}

.clearboth {
  clear: both;
}

@media print, screen and (min-width: 40em) {
  figure.alignleft,
figure.alignright {
    max-width: calc(50% - 1em);
  }
  figure.alignleft {
    float: left;
    margin-right: 1em;
  }
  figure.alignright {
    float: right;
    margin-left: 1em;
  }
}
@media print, screen and (max-width: 39.99875em) {
  figure.alignleft {
    margin-bottom: 1em;
  }
  figure.alignright {
    margin-bottom: 1em;
  }
}
.page-contents .has-text-align-center {
  text-align: center;
}
.page-contents .has-text-align-right {
  text-align: right;
}
.page-contents .has-text-align-left {
  text-align: left;
}
.page-contents .aligncenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.page-contents .alignright {
  float: right;
}
.page-contents .alignleft {
  float: left;
}
.page-contents .alignwide {
  width: 150%;
  margin-left: -25%;
  margin-right: -25%;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .page-contents .alignwide {
    width: 150%;
    margin-left: -25%;
    margin-right: -25%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .page-contents .alignwide {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
.page-contents .alignfull .alignwide, .page-contents .home-program-headline .alignwide {
  width: 100%;
  max-width: 76rem;
  margin: auto;
}
.page-contents .wp-block-image img {
  max-width: 100%;
  height: auto;
}

.wp-block-columns {
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: -0.5em;
  margin-right: -0.5em;
}
@media print, screen and (max-width: 39.99875em) {
  .wp-block-columns {
    flex-direction: column;
  }
}
.wp-block-columns .wp-block-column {
  width: 100%;
  flex: 0 1 auto;
  margin-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1em;
}
@media print, screen and (max-width: 39.99875em) {
  .wp-block-columns .wp-block-column {
    padding-left: 0;
    padding-right: 0;
  }
}

@media print, screen and (min-width: 40em) {
  .has-3-columns .wp-block-column {
    width: calc(33.333% - 0.5em);
  }
  .has-4-columns .wp-block-column {
    width: calc(25% - 0.5em);
  }
}

.site-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32em;
  padding: 2em 4em 0;
  background-color: #e6eaea;
}
@media print, screen and (max-width: 39.99875em) {
  .site-footer {
    height: 40em;
    padding: 2em 1.5em 0;
  }
}
.site-footer a {
  transition: color 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  text-decoration: underline;
}

.footer-contact {
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.footer-contact__title {
  margin-bottom: 0.5em;
}
.footer-contact dl {
  margin: 0;
}
.footer-contact dd {
  margin: 0;
}
@media print, screen and (max-width: 63.99875em) {
  .footer-contact {
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    width: auto;
  }
}
.footer-contact__copyright, .footer-contact__twitter {
  margin-left: 0.5em;
}
@media print, screen and (max-width: 63.99875em) {
  .footer-contact__copyright, .footer-contact__twitter {
    align-self: flex-end;
    margin-left: 0;
  }
}
.footer-contact__copyright {
  margin-top: 1em;
}
.footer-contact__twitter {
  line-height: 1;
  font-size: 1.875rem;
}
.footer-contact__twitter a {
  display: block;
  padding: 0 0.25em;
  text-decoration: none;
  color: #1DA1EF;
}

.EOF {
  order: 3;
  align-self: center;
}
@media print, screen and (max-width: 39.99875em) {
  .EOF {
    margin-right: -1.5em;
    align-self: flex-end;
  }
}
.EOF a, .EOF__logo {
  display: inline-block;
  padding: 1em;
  line-height: 23px;
  font-size: 30px;
  color: #7B8987;
}

form b {
  font-family: sans-serif;
  font-weight: 900;
  font-size: 0.875rem;
  color: #932275;
}
form textarea,
form input[type=text],
form input[type=number],
form input[type=email] {
  margin: 0;
  margin-right: 1em;
  padding: 0.5em 1em;
  min-width: 16em;
  max-width: 100%;
  min-height: 100%;
  border: 3px solid #7B8987;
  border-radius: 0.5em;
  line-height: 1.5;
  font-size: 1rem;
  font-family: sans-serif !important;
}
form textarea:focus,
form input[type=text]:focus,
form input[type=number]:focus,
form input[type=email]:focus {
  background-color: #e6eaea;
  border-color: #e6eaea;
  border-radius: 0;
}
form input[name=user_name],
form input[name=user_mail] {
  display: block;
  margin-bottom: 0.5em;
}
form input[name=user_mail] {
  width: 32em !important;
}
form textarea {
  height: 10em;
}
@media print, screen and (max-width: 39.99875em) {
  form textarea {
    height: 5em;
  }
}
form select {
  position: relative;
  appearance: none;
  padding: 0.75em 3em 0.75em 1em;
  border: 3px solid #7B8987;
  border-radius: 0.5em;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23626E6C'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 12px) calc(50% + 3px);
  background-repeat: no-repeat;
  font-size: calc(0.2083333333vw + 13.3333333333px);
}
form select:focus {
  border-color: #fff;
  border-radius: 0;
}
form select + br + input[type=text] {
  margin-top: -3px;
}
form input[type=button],
form input[type=submit] {
  appearance: none;
  margin-top: 1em;
  margin-bottom: 2em;
}
@media print, screen and (max-width: 39.99875em) {
  form input[type=button],
form input[type=submit] {
    margin-top: 0;
  }
}
form input[name=resign],
form input[name=submitBack] {
  background-color: #525F5D !important;
  box-shadow: 0 5px 12px rgba(82, 95, 93, 0.4) !important;
}
form .error {
  display: block;
  color: #932275 !important;
}
form .vertical-item {
  margin-top: 1em;
}

.logo-do-it abbr {
  letter-spacing: 1px;
}

.site-title {
  position: relative;
  margin: 0 0 1.25em;
}
.site-title__logo {
  font-size: 60px;
  line-height: 50px;
}
@media print, screen and (max-width: 39.99875em) {
  .site-title__logo {
    font-size: 50px;
    line-height: 40px;
  }
}
.site-title__word-wrap {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
}
.site-title__word {
  margin: 0;
  letter-spacing: 0.125em;
  line-height: 1.25;
}
.site-title__word::first-letter {
  color: #09ae96;
}
@media print, screen and (max-width: 39.99875em) {
  .site-title__word {
    font-size: 12px;
  }
}
.site-title dfn {
  font-style: normal;
}
.site-title a {
  color: #626E6C;
}
.site-header {
  position: absolute;
  z-index: 256;
  min-width: 186px;
  padding: 1.5em 0 0 1.25em;
  background-color: #fff;
  color: #626E6C;
}
@media print, screen and (max-width: 39.99875em) {
  .site-header {
    min-width: 8.5em;
    padding-top: 1.25em;
    padding-right: 0;
  }
}
.site-header .ss-icon.ss-community:before {
  font-family: "SSCommunity";
}
.site-header__button {
  display: block;
}

.site-header__button,
.donation-nav__lists {
  margin-left: -1.25em;
  padding-left: 1.25em;
  border-top: 2px solid #09ae96;
  background-color: #e5f8f2;
  font-size: calc(0.2083333333vw + 13.3333333333px);
  line-height: calc(3em - 3px);
}
.site-header__button, .site-header__button li,
.donation-nav__lists,
.donation-nav__lists li {
  color: #199482;
}

.donation-nav__lists {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  z-index: 256;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.donation-nav__lists li {
  position: relative;
  padding-left: 1em;
  padding-right: 1.25em;
}
.donation-nav__lists li:before {
  margin-right: 0.5em;
}
body {
  overflow-x: hidden;
}

.site-contents {
  position: relative;
  z-index: 1;
  margin-bottom: 32em;
  background-color: #fff;
}
@media print, screen and (max-width: 39.99875em) {
  .site-contents {
    margin-bottom: 40em;
  }
}
.site-contents__inner {
  margin: 0 auto;
}
@media print, screen and (min-width: 64em) {
  .site-contents__inner {
    padding: 4em;
  }
}
@media print, screen and (min-width: 40em) {
  .site-contents__inner {
    max-width: 64em;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .site-contents__inner {
    padding: 0 2em 4em;
  }
}
.home .site-contents__inner {
  padding-top: 0;
  padding-bottom: 0;
}
.site-contents p:empty {
  display: none;
}

.page-contents .alignfull, .page-contents .home-program-headline {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;
}

.menu-item-has-children a:focus ~ .sub-menu,
.menu-item:hover .sub-menu,
.sub-menu.focus {
  display: block;
  transform: translateY(0);
  opacity: 1;
  left: -1em;
}

.sub-menu {
  transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), left 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  z-index: 64;
  transform: translateY(calc(-100% - 2em));
  top: 100%;
  left: -2em;
  opacity: 0;
  margin: 0;
  padding: 1em;
  background-color: #fff;
  list-style: none;
}
.sub-menu li {
  white-space: nowrap;
}
@media print, screen and (max-width: 63.99875em) {
  .sub-menu {
    display: none;
    transform: translateY(-100vh);
    box-shadow: 0px 0px 50px -8px rgba(68, 80, 78, 0.5);
  }
  .sub-menu a {
    line-height: 3em;
  }
  .menu-open .sub-menu {
    display: block;
  }
}

@media print, screen and (max-width: 63.99875em) {
  .global-nav {
    transition: background-color 0.1s linear;
    position: fixed;
    z-index: 256;
    top: 100%;
    width: 100%;
    height: 0;
    background-color: rgba(98, 110, 108, 0);
  }
  .menu-open .global-nav {
    top: 0;
    height: 100%;
    background-color: rgba(98, 110, 108, 0.5);
  }
  .global-nav__lists {
    transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0.1s, opacity 0.4s linear 0.1s !important;
    transform: scale(1.1);
    opacity: 0;
    position: fixed;
    z-index: 255;
    top: 100vh;
    left: 1em;
    right: 1em;
    flex-direction: column;
    padding: 2em 1.75em 0em;
  }
  .menu-open .global-nav__lists {
    transform: scale(1);
    opacity: 1;
    top: 1em;
    bottom: 1em;
  }
  .global-nav__lists .menu-item {
    width: 100%;
    font-size: calc(0.625vw + 14px);
  }
  .global-nav__lists .menu-item:before {
    margin-right: 1em;
    font-family: "SSCommunity";
    vertical-align: -0.125em;
  }
  .global-nav__lists .menu-item:before {
    content: "▶";
  }
  .global-nav__lists .menu-item.menu-item-has-children:before {
    content: "+";
  }
}
.global-nav__lists {
  transition: height 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.global-nav__lists a {
  color: #525F5D;
}
.wp-logged-in .global-nav__lists {
  margin-top: 32px;
}
@media print, screen and (min-width: 64em) {
  .global-nav__lists {
    position: fixed;
    z-index: 128;
    top: 0;
    left: 0;
    right: 0;
    height: 5em;
  }
  .page-header-out .global-nav__lists {
    height: 4em;
  }
  .global-nav__lists.focus {
    z-index: 256;
  }
}
.global-nav__lists a {
  z-index: 1;
  display: inline-block;
  padding: 0.5em;
}
@media screen and (max-width: 68em) {
  .global-nav__lists a {
    letter-spacing: -0.0125em;
    font-size: 0.9375rem;
  }
}
.global-nav__lists a:hover {
  background-color: #09ae96;
  color: #fff;
}
.global-nav .menu-item-has-children li a {
  padding-left: 1em;
  padding-right: 1em;
}
.global-nav .current-menu-parent > a,
.global-nav .current-menu-item > a {
  border-bottom: 2px solid #09ae96;
}
.global-nav .current-menu-parent .current-menu-item > a,
.global-nav .current-menu-item .current-menu-item > a {
  color: #199482;
  border-bottom: none;
}
.global-nav .current-menu-parent .current-menu-item > a:hover,
.global-nav .current-menu-item .current-menu-item > a:hover {
  color: #fff;
}
.global-nav .menu-item {
  position: relative;
  order: 1;
}
.global-nav__menu {
  position: fixed;
  z-index: 256;
  top: 0;
  right: 0;
  background-color: #fff;
}
@media print, screen and (min-width: 64em) {
  .global-nav__menu {
    display: none;
  }
}

.global-nav__menu-label, .global-nav__list--lang {
  width: 62px;
}
.global-nav__list--home {
  order: 0;
  width: 186px;
}
.global-nav__list--lang {
  order: 9;
  visibility: hidden;
}
.global-nav__menu-label, .global-nav__list--home, .global-nav__list--lang {
  cursor: pointer;
  appearance: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 62px;
  background-color: #fff;
  border: none;
  line-height: 1;
}
.global-nav__menu-label.ss-icon:before, .global-nav__list--home.ss-icon:before, .global-nav__list--lang.ss-icon:before {
  display: block;
  margin: 0 !important;
  font-size: 1.4375rem;
}
.global-nav__menu-label a, .global-nav__menu-label span, .global-nav__list--home a, .global-nav__list--home span, .global-nav__list--lang a, .global-nav__list--lang span {
  font-size: 0.6875rem;
}
html[lang=ja] .ss-icon:before {
  margin-right: 0.25em;
  vertical-align: -0.125em;
}
html[lang=ja] .ss-icon:after {
  margin-left: 0.25em;
  vertical-align: -0.125em;
}

.footer-nav {
  align-self: flex-end;
}
.footer-nav__lists {
  display: flex;
  align-items: center;
}
@media print, screen and (max-width: 39.99875em) {
  .footer-nav__lists {
    flex-direction: column;
    align-items: flex-end;
  }
}
.footer-nav a {
  display: inline-block;
  padding: 0 1em;
  line-height: 3em;
}
.footer-nav .ss-icon a {
  padding: 0 0.5em;
  text-decoration: none;
  line-height: 2.5em;
  font-size: 1.5rem;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-links .page-numbers {
  padding: 1em;
}
#join {
  margin: 0;
}

.section-part-in {
  position: relative;
  padding: 2em;
  color: #199482;
}
@media print, screen and (min-width: 64em) {
  .section-part-in {
    padding: 8em 4em 4em;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-part-in {
    padding: 2em;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .section-part-in img {
    height: 100%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .section-part-in {
    flex-direction: column;
  }
}
.section-part-in .wp-block-column:last-of-type,
.section-part-in .wp-block-column:first-of-type {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.93);
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-part-in .wp-block-column:last-of-type,
.section-part-in .wp-block-column:first-of-type {
    width: calc(50% - 1em);
  }
}
.section-part-in .wp-block-column:nth-of-type(2) {
  width: 0;
}
.section-part-in .wp-block-column p {
  flex: 1 1 auto;
}
.section-part-in .wp-block-column h3, .section-part-in .wp-block-column .wp-block-button {
  flex: 0 0 auto;
}

.section-news-letter {
  background-color: #09ae96;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .section-news-letter .wp-block-column {
    width: 25%;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-news-letter .wp-block-column {
    width: 12.5%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .section-news-letter .wp-block-column {
    width: 100%;
  }
}
.section-news-letter .wp-block-column:nth-of-type(2) {
  padding: 2em;
}
@media print, screen and (min-width: 64em) {
  .section-news-letter .wp-block-column:nth-of-type(2) {
    width: 50%;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-news-letter .wp-block-column:nth-of-type(2) {
    width: 75%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .section-news-letter .wp-block-column:nth-of-type(2) {
    width: 100%;
  }
}
.section-news-letter h2.ss-icon:before {
  content: "✉";
  font-family: "SSCommunity";
  vertical-align: baseline;
}
@media print, screen and (min-width: 64em) {
  .section-news-letter h2.ss-icon {
    font-size: 3.75rem;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-news-letter h2.ss-icon {
    font-size: 6vw;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .section-news-letter h2.ss-icon {
    font-size: 12vw;
  }
}

.bg-image .wp-block-media-text__media img, .bg-image-center .wp-block-column:nth-of-type(2) img {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-image, .bg-image-center {
  position: relative;
  overflow: hidden;
}
.bg-image .wp-block-media-text__media img, .bg-image-center .wp-block-column:nth-of-type(2) img {
  width: 100%;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .bg-image .wp-block-media-text__media img {
    height: 100%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .bg-image .wp-block-media-text__media img {
    position: static;
    height: 100%;
  }
}
.bg-image-center .wp-block-column:nth-of-type(2) img {
  height: 100%;
}
.bg-image .wp-block-media-text__content {
  padding: 2em;
}
@media print, screen and (min-width: 64em) {
  .bg-image .wp-block-media-text__content {
    padding: 4em;
  }
}
@media print, screen and (min-width: 40em) {
  .bg-image .wp-block-media-text__content {
    width: 50%;
  }
}
.bg-image .wp-block-media-text__content {
  margin-left: auto;
}
.bg-image.has-media-on-the-right .wp-block-media-text__content {
  margin-left: 0;
  margin-right: auto;
}
.bg-image .wp-block-button, .bg-image-center .wp-block-button {
  margin-top: 2em;
}
.site-contents .bg-image.has-light-green-cyan-background-color, .site-contents .bg-image.has-pale-cyan-blue-background-color, .site-contents .bg-image.has-vivid-cyan-blue-background-color {
  background-color: transparent;
  color: #fff;
}

.has-light-green-cyan-background-color .wp-block-media-text__content {
  background-color: rgba(9, 174, 150, 0.93);
}

.has-pale-cyan-blue-background-color .wp-block-media-text__content {
  background-color: rgba(0, 160, 209, 0.93);
}

.has-vivid-cyan-blue-background-color .wp-block-media-text__content {
  background-color: rgba(0, 128, 167, 0.93);
}

.to-top-button {
  position: relative;
  display: block;
  text-align: center;
  font-size: 2.0625rem;
}
.to-top-button a {
  line-height: 1.5em;
}
.site-contents .to-top-button a:before {
  margin-right: 0;
}
.to-top-button .visually-hidden {
  top: 0;
  left: 0;
}

.page-header {
  position: relative;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-bottom: 3em;
  padding: 1em;
}
.page-header__title-wraper {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: calc(100% - 2em);
  padding: 1em;
  padding-left: 2em;
  background-color: #fff;
  color: #626E6C;
}

.page-cover img {
  height: calc(90vh - 80px);
  max-height: 540px;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .page-cover img {
    max-height: 430px;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .page-cover img {
    max-height: 320px;
  }
}

.page-cover img {
  width: 100%;
}

.page-title {
  margin: 0 0.5em 0 0;
  font-size: calc(0.625vw + 20px);
  line-height: 1.25;
}
.page-title .en, .page-title .en b, .en .page-title b, .page-title .en strong, .en .page-title strong {
  text-transform: capitalize;
  font-size: 3.125rem;
}

.page-anchor {
  position: absolute;
  top: -4em;
}

.page-index {
  transition: top 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  top: 0;
  right: -1em;
}
.page-header-out .page-index {
  position: fixed;
  z-index: 64;
  top: 5em;
  right: 0;
}
.wp-logged-in .page-header-out .page-index {
  top: calc(4em + 45px);
}
.page-index__title {
  margin: 0;
  font-size: calc(0.2083333333vw + 13.3333333333px);
}
.page-index__lists {
  display: none;
  position: absolute;
  z-index: 64;
  top: 100%;
  right: 0;
  width: 24em;
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: 0px 0px 50px -8px rgba(68, 80, 78, 0.5);
}
.page-index__lists.open {
  display: block;
}
.page-index__lists li:not(:last-child) {
  border-bottom: 1px solid #e6eaea;
}
.page-index__lists a {
  display: flex;
  align-items: center;
  padding: 1em;
  line-height: 1.25;
}
.page-index__lists a:hover {
  background-color: #09ae96;
  color: #fff;
}
.page-index__lists a:before {
  content: "\f501";
  margin-top: 0.5em;
  margin-right: 1em;
  font-family: "SSCommunity";
}
.page-index__close {
  position: absolute;
  bottom: 100%;
  right: 0;
}
.page-index__open-button, .page-index__close-button {
  cursor: pointer;
  appearance: none;
  padding: 0.625em 1em 0.5em;
  background-color: #fff;
  border: none;
  white-space: nowrap;
  font-size: 0.875rem;
  color: #09ae96;
}
.page-index__open-button i, .page-index__close-button i {
  margin-left: 0.25em;
  vertical-align: -0.125em;
}

.section-about.bg-image {
  padding: 2em 0 0;
}
@media print, screen and (min-width: 64em) {
  .section-about.bg-image {
    justify-content: space-around;
  }
}
@media print, screen and (min-width: 40em) {
  .section-about.bg-image {
    padding: 0 4em;
  }
}
.section-about.bg-image .wp-block-media-text__content {
  max-width: 46em;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.92);
}
@media print, screen and (min-width: 64em) {
  .section-about.bg-image .wp-block-media-text__content {
    width: calc(75% - 8em);
    padding: 3em 4em 2.5em;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-about.bg-image .wp-block-media-text__content {
    width: 100%;
    padding: 2.5em 3em 2em;
  }
}
.section-about.bg-image .wp-block-media-text__media img {
  height: 100%;
}

.section-support .wp-block-column {
  display: flex;
  flex-direction: column;
}
.section-support .wp-block-column h4 {
  margin: 0 0 0.5em;
}

.thumb-cover {
  order: -1;
  height: 12em;
  margin-bottom: 1em;
}
.thumb-cover img {
  width: 100%;
  height: 100%;
}

.page-contents h2:not(.visually-hidden),
.editor-writing-flow h2:not(.visually-hidden) {
  position: relative;
  margin-top: 3em;
  padding-top: 3em;
  line-height: calc(0.46875vw + 30px);
  font-size: calc(0.625vw + 20px);
  color: #199482;
}
.page-contents h2:not(.visually-hidden):before,
.editor-writing-flow h2:not(.visually-hidden):before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(100% + 1em);
  width: 50vw;
  border-right: 1vw solid #199482;
  background-color: #e6eaea;
}
.page-contents .wp-block-columns h2,
.editor-writing-flow .wp-block-columns h2 {
  margin-top: 0;
}
.page-contents h3,
.editor-writing-flow h3 {
  position: relative;
  margin-top: 2em;
  padding: calc(1em - 1px) 0.5em;
  border-top: 1px solid #199482;
  border-bottom: 1px solid #199482;
  line-height: calc(0.390625vw + 25px);
  font-size: calc(0.625vw + 14px);
}
.page-contents h4,
.editor-writing-flow h4 {
  display: inline-block;
  position: relative;
  margin-top: 2em;
  padding-top: 0.5em;
  letter-spacing: 0.125em;
  line-height: calc(0.3125vw + 20px);
  font-size: calc(0.2083333333vw + 13.3333333333px);
}
.page-contents h4:before,
.editor-writing-flow h4:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0.125em;
  border-radius: 0.0625em;
  background-color: #09ae96;
}
.page-contents h4:after,
.editor-writing-flow h4:after {
  content: "\a";
  white-space: pre;
}
.page-contents ol.flow,
.editor-writing-flow ol.flow {
  padding-left: 0;
}
.page-contents ol.flow li,
.editor-writing-flow ol.flow li {
  margin-bottom: 2em;
  padding: 1em;
  border: 2px solid #ea7900;
  border-radius: 0.5em;
  line-height: 1.5;
}
.page-contents ol.flow li:not(:last-child):after,
.editor-writing-flow ol.flow li:not(:last-child):after {
  content: "V";
  display: block;
  position: absolute;
  top: 100%;
  left: 50px;
  width: 0;
  height: 0.5em;
  padding-top: 0.75em;
  border-left: 4px solid;
  border-bottom: 6px solid;
  line-height: 1;
  text-indent: -0.375em;
  font-size: 30px;
  color: #ea7900;
}
.page-contents ol:not([start]),
.editor-writing-flow ol:not([start]) {
  counter-reset: list-count;
}
.page-contents ol,
.editor-writing-flow ol {
  margin-left: 0;
  list-style-type: none;
}
.page-contents ol li,
.editor-writing-flow ol li {
  display: flex;
  position: relative;
  margin-bottom: 1em;
}
.page-contents ol li:before,
.editor-writing-flow ol li:before {
  position: relative;
  top: -0.125em;
  counter-increment: list-count;
  content: attr(start)counter(list-count);
  margin-right: 0.5em;
  font-size: 175%;
  color: #ea7900;
}
.page-contents iframe,
.editor-writing-flow iframe {
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}
.page-contents .wp-block-button,
.editor-writing-flow .wp-block-button {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.page-contents .has-3-columns .trim-image,
.editor-writing-flow .has-3-columns .trim-image {
  position: relative;
  height: 0;
  padding-top: 40%;
}
.page-contents .has-3-columns .trim-image img,
.editor-writing-flow .has-3-columns .trim-image img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.page-contents p > a, .page-contents li > a, .page-contents dd > a,
.editor-writing-flow p > a,
.editor-writing-flow li > a,
.editor-writing-flow dd > a {
  text-decoration: underline;
}

.button, .site-contents .btn,
.site-contents .button,
.site-contents form input[type=button],
form .site-contents input[type=button],
.site-contents form input[type=submit],
form .site-contents input[type=submit],
.site-contents .wp-block-button__link,
.editor-writing-flow .btn,
.editor-writing-flow .button,
.editor-writing-flow form input[type=button],
form .editor-writing-flow input[type=button],
.editor-writing-flow form input[type=submit],
form .editor-writing-flow input[type=submit],
.editor-writing-flow .wp-block-button__link, form input[type=button],
form input[type=submit] {
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: center;
  transform: scale(1);
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 1em;
  border: none;
  border-radius: 6px;
  background-color: #09ae96;
  box-shadow: 0 5px 12px rgba(25, 148, 130, 0.4);
  text-align: center;
  text-decoration: none;
  white-space: normal;
  overflow-wrap: break-word;
  line-height: 1.25;
  font-size: 1em;
  color: #fff;
}
.button:focus, .site-contents .btn:focus,
.site-contents .wp-block-button__link:focus,
.editor-writing-flow .btn:focus,
.editor-writing-flow .wp-block-button__link:focus, form input[type=button]:focus,
form input[type=submit]:focus, .button:hover, .site-contents .btn:hover,
.site-contents .wp-block-button__link:hover,
.editor-writing-flow .btn:hover,
.editor-writing-flow .wp-block-button__link:hover, form input[type=button]:hover,
form input[type=submit]:hover {
  transform: scale(1.05);
}
.button em, .site-contents .btn em,
.site-contents .button em,
.site-contents form input[type=button] em,
form .site-contents input[type=button] em,
.site-contents form input[type=submit] em,
form .site-contents input[type=submit] em,
.site-contents .wp-block-button__link em,
.editor-writing-flow .btn em,
.editor-writing-flow .button em,
.editor-writing-flow form input[type=button] em,
form .editor-writing-flow input[type=button] em,
.editor-writing-flow form input[type=submit] em,
form .editor-writing-flow input[type=submit] em,
.editor-writing-flow .wp-block-button__link em, form input[type=button] em,
form input[type=submit] em {
  font-size: 0.75rem;
  font-style: normal;
}

.editor-writing-flow .wp-block {
  max-width: 1028px;
}
@media (min-width: 600px) {
  .editor-writing-flow .editor-block-list__layout .editor-block-list__block[data-align=full] {
    max-width: none;
  }
}
.editor-writing-flow p {
  line-height: 1.5;
}

.site-contents .has-vivid-cyan-blue-background-color,
.editor-writing-flow .has-vivid-cyan-blue-background-color {
  background-color: #00A0D1;
  box-shadow: 0 5px 12px rgba(0, 128, 167, 0.4);
}
.site-contents .has-pale-pink-background-color a:focus,
.site-contents .has-vivid-red-background-color a:focus,
.site-contents .has-luminous-vivid-orange-background-color a:focus,
.site-contents .has-luminous-vivid-amber-background-color a:focus,
.site-contents .has-light-green-cyan-background-color a:focus,
.site-contents .has-vivid-green-cyan-background-color a:focus,
.site-contents .has-pale-cyan-blue-background-color a:focus,
.site-contents .has-vivid-cyan-blue-background-color a:focus,
.site-contents .has-very-light-gray-background-color a:focus,
.site-contents .has-cyan-bluish-gray-background-color a:focus,
.site-contents .has-very-dark-gray-background-color a:focus,
.editor-writing-flow .has-pale-pink-background-color a:focus,
.editor-writing-flow .has-vivid-red-background-color a:focus,
.editor-writing-flow .has-luminous-vivid-orange-background-color a:focus,
.editor-writing-flow .has-luminous-vivid-amber-background-color a:focus,
.editor-writing-flow .has-light-green-cyan-background-color a:focus,
.editor-writing-flow .has-vivid-green-cyan-background-color a:focus,
.editor-writing-flow .has-pale-cyan-blue-background-color a:focus,
.editor-writing-flow .has-vivid-cyan-blue-background-color a:focus,
.editor-writing-flow .has-very-light-gray-background-color a:focus,
.editor-writing-flow .has-cyan-bluish-gray-background-color a:focus,
.editor-writing-flow .has-very-dark-gray-background-color a:focus {
  outline: #fff auto 5px;
}
.site-contents .has-pale-pink-background-color .btn,
.site-contents .has-pale-pink-background-color .button,
.site-contents .has-pale-pink-background-color form input[type=button],
form .site-contents .has-pale-pink-background-color input[type=button],
.site-contents .has-pale-pink-background-color form input[type=submit],
form .site-contents .has-pale-pink-background-color input[type=submit],
.site-contents .has-pale-pink-background-color .btn,
.site-contents .has-pale-pink-background-color .button,
.site-contents .has-pale-pink-background-color form input[type=button],
.site-contents form .has-pale-pink-background-color input[type=button],
form .site-contents .has-pale-pink-background-color input[type=button],
.site-contents .has-pale-pink-background-color form input[type=submit],
.site-contents form .has-pale-pink-background-color input[type=submit],
form .site-contents .has-pale-pink-background-color input[type=submit],
.site-contents .has-pale-pink-background-color .wp-block-button__link,
.site-contents .has-pale-pink-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-pale-pink-background-color .btn,
.site-contents .has-pale-pink-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-pale-pink-background-color .button,
.site-contents .has-pale-pink-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-pale-pink-background-color input[type=button],
.site-contents .has-pale-pink-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-pale-pink-background-color input[type=button],
.site-contents .has-pale-pink-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-pale-pink-background-color input[type=submit],
.site-contents .has-pale-pink-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-pale-pink-background-color input[type=submit],
.site-contents .has-pale-pink-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-pale-pink-background-color .wp-block-button__link,
.site-contents .has-pale-pink-background-color .wp-block-button__link,
.site-contents .has-vivid-red-background-color .btn,
.site-contents .has-vivid-red-background-color .button,
.site-contents .has-vivid-red-background-color form input[type=button],
form .site-contents .has-vivid-red-background-color input[type=button],
.site-contents .has-vivid-red-background-color form input[type=submit],
form .site-contents .has-vivid-red-background-color input[type=submit],
.site-contents .has-vivid-red-background-color .btn,
.site-contents .has-vivid-red-background-color .button,
.site-contents .has-vivid-red-background-color form input[type=button],
.site-contents form .has-vivid-red-background-color input[type=button],
form .site-contents .has-vivid-red-background-color input[type=button],
.site-contents .has-vivid-red-background-color form input[type=submit],
.site-contents form .has-vivid-red-background-color input[type=submit],
form .site-contents .has-vivid-red-background-color input[type=submit],
.site-contents .has-vivid-red-background-color .wp-block-button__link,
.site-contents .has-vivid-red-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-vivid-red-background-color .btn,
.site-contents .has-vivid-red-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-vivid-red-background-color .button,
.site-contents .has-vivid-red-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-vivid-red-background-color input[type=button],
.site-contents .has-vivid-red-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-vivid-red-background-color input[type=button],
.site-contents .has-vivid-red-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-vivid-red-background-color input[type=submit],
.site-contents .has-vivid-red-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-vivid-red-background-color input[type=submit],
.site-contents .has-vivid-red-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-vivid-red-background-color .wp-block-button__link,
.site-contents .has-vivid-red-background-color .wp-block-button__link,
.site-contents .has-luminous-vivid-orange-background-color .btn,
.site-contents .has-luminous-vivid-orange-background-color .button,
.site-contents .has-luminous-vivid-orange-background-color form input[type=button],
form .site-contents .has-luminous-vivid-orange-background-color input[type=button],
.site-contents .has-luminous-vivid-orange-background-color form input[type=submit],
form .site-contents .has-luminous-vivid-orange-background-color input[type=submit],
.site-contents .has-luminous-vivid-orange-background-color .btn,
.site-contents .has-luminous-vivid-orange-background-color .button,
.site-contents .has-luminous-vivid-orange-background-color form input[type=button],
.site-contents form .has-luminous-vivid-orange-background-color input[type=button],
form .site-contents .has-luminous-vivid-orange-background-color input[type=button],
.site-contents .has-luminous-vivid-orange-background-color form input[type=submit],
.site-contents form .has-luminous-vivid-orange-background-color input[type=submit],
form .site-contents .has-luminous-vivid-orange-background-color input[type=submit],
.site-contents .has-luminous-vivid-orange-background-color .wp-block-button__link,
.site-contents .has-luminous-vivid-orange-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-luminous-vivid-orange-background-color .btn,
.site-contents .has-luminous-vivid-orange-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-luminous-vivid-orange-background-color .button,
.site-contents .has-luminous-vivid-orange-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-luminous-vivid-orange-background-color input[type=button],
.site-contents .has-luminous-vivid-orange-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-luminous-vivid-orange-background-color input[type=button],
.site-contents .has-luminous-vivid-orange-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-luminous-vivid-orange-background-color input[type=submit],
.site-contents .has-luminous-vivid-orange-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-luminous-vivid-orange-background-color input[type=submit],
.site-contents .has-luminous-vivid-orange-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-luminous-vivid-orange-background-color .wp-block-button__link,
.site-contents .has-luminous-vivid-orange-background-color .wp-block-button__link,
.site-contents .has-luminous-vivid-amber-background-color .btn,
.site-contents .has-luminous-vivid-amber-background-color .button,
.site-contents .has-luminous-vivid-amber-background-color form input[type=button],
form .site-contents .has-luminous-vivid-amber-background-color input[type=button],
.site-contents .has-luminous-vivid-amber-background-color form input[type=submit],
form .site-contents .has-luminous-vivid-amber-background-color input[type=submit],
.site-contents .has-luminous-vivid-amber-background-color .btn,
.site-contents .has-luminous-vivid-amber-background-color .button,
.site-contents .has-luminous-vivid-amber-background-color form input[type=button],
.site-contents form .has-luminous-vivid-amber-background-color input[type=button],
form .site-contents .has-luminous-vivid-amber-background-color input[type=button],
.site-contents .has-luminous-vivid-amber-background-color form input[type=submit],
.site-contents form .has-luminous-vivid-amber-background-color input[type=submit],
form .site-contents .has-luminous-vivid-amber-background-color input[type=submit],
.site-contents .has-luminous-vivid-amber-background-color .wp-block-button__link,
.site-contents .has-luminous-vivid-amber-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-luminous-vivid-amber-background-color .btn,
.site-contents .has-luminous-vivid-amber-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-luminous-vivid-amber-background-color .button,
.site-contents .has-luminous-vivid-amber-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-luminous-vivid-amber-background-color input[type=button],
.site-contents .has-luminous-vivid-amber-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-luminous-vivid-amber-background-color input[type=button],
.site-contents .has-luminous-vivid-amber-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-luminous-vivid-amber-background-color input[type=submit],
.site-contents .has-luminous-vivid-amber-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-luminous-vivid-amber-background-color input[type=submit],
.site-contents .has-luminous-vivid-amber-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-luminous-vivid-amber-background-color .wp-block-button__link,
.site-contents .has-luminous-vivid-amber-background-color .wp-block-button__link,
.site-contents .has-light-green-cyan-background-color .btn,
.site-contents .has-light-green-cyan-background-color .button,
.site-contents .has-light-green-cyan-background-color form input[type=button],
form .site-contents .has-light-green-cyan-background-color input[type=button],
.site-contents .has-light-green-cyan-background-color form input[type=submit],
form .site-contents .has-light-green-cyan-background-color input[type=submit],
.site-contents .has-light-green-cyan-background-color .btn,
.site-contents .has-light-green-cyan-background-color .button,
.site-contents .has-light-green-cyan-background-color form input[type=button],
.site-contents form .has-light-green-cyan-background-color input[type=button],
form .site-contents .has-light-green-cyan-background-color input[type=button],
.site-contents .has-light-green-cyan-background-color form input[type=submit],
.site-contents form .has-light-green-cyan-background-color input[type=submit],
form .site-contents .has-light-green-cyan-background-color input[type=submit],
.site-contents .has-light-green-cyan-background-color .wp-block-button__link,
.site-contents .has-light-green-cyan-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-light-green-cyan-background-color .btn,
.site-contents .has-light-green-cyan-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-light-green-cyan-background-color .button,
.site-contents .has-light-green-cyan-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-light-green-cyan-background-color input[type=button],
.site-contents .has-light-green-cyan-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-light-green-cyan-background-color input[type=button],
.site-contents .has-light-green-cyan-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-light-green-cyan-background-color input[type=submit],
.site-contents .has-light-green-cyan-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-light-green-cyan-background-color input[type=submit],
.site-contents .has-light-green-cyan-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-light-green-cyan-background-color .wp-block-button__link,
.site-contents .has-light-green-cyan-background-color .wp-block-button__link,
.site-contents .has-vivid-green-cyan-background-color .btn,
.site-contents .has-vivid-green-cyan-background-color .button,
.site-contents .has-vivid-green-cyan-background-color form input[type=button],
form .site-contents .has-vivid-green-cyan-background-color input[type=button],
.site-contents .has-vivid-green-cyan-background-color form input[type=submit],
form .site-contents .has-vivid-green-cyan-background-color input[type=submit],
.site-contents .has-vivid-green-cyan-background-color .btn,
.site-contents .has-vivid-green-cyan-background-color .button,
.site-contents .has-vivid-green-cyan-background-color form input[type=button],
.site-contents form .has-vivid-green-cyan-background-color input[type=button],
form .site-contents .has-vivid-green-cyan-background-color input[type=button],
.site-contents .has-vivid-green-cyan-background-color form input[type=submit],
.site-contents form .has-vivid-green-cyan-background-color input[type=submit],
form .site-contents .has-vivid-green-cyan-background-color input[type=submit],
.site-contents .has-vivid-green-cyan-background-color .wp-block-button__link,
.site-contents .has-vivid-green-cyan-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-vivid-green-cyan-background-color .btn,
.site-contents .has-vivid-green-cyan-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-vivid-green-cyan-background-color .button,
.site-contents .has-vivid-green-cyan-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-vivid-green-cyan-background-color input[type=button],
.site-contents .has-vivid-green-cyan-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-vivid-green-cyan-background-color input[type=button],
.site-contents .has-vivid-green-cyan-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-vivid-green-cyan-background-color input[type=submit],
.site-contents .has-vivid-green-cyan-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-vivid-green-cyan-background-color input[type=submit],
.site-contents .has-vivid-green-cyan-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-vivid-green-cyan-background-color .wp-block-button__link,
.site-contents .has-vivid-green-cyan-background-color .wp-block-button__link,
.site-contents .has-pale-cyan-blue-background-color .btn,
.site-contents .has-pale-cyan-blue-background-color .button,
.site-contents .has-pale-cyan-blue-background-color form input[type=button],
form .site-contents .has-pale-cyan-blue-background-color input[type=button],
.site-contents .has-pale-cyan-blue-background-color form input[type=submit],
form .site-contents .has-pale-cyan-blue-background-color input[type=submit],
.site-contents .has-pale-cyan-blue-background-color .btn,
.site-contents .has-pale-cyan-blue-background-color .button,
.site-contents .has-pale-cyan-blue-background-color form input[type=button],
.site-contents form .has-pale-cyan-blue-background-color input[type=button],
form .site-contents .has-pale-cyan-blue-background-color input[type=button],
.site-contents .has-pale-cyan-blue-background-color form input[type=submit],
.site-contents form .has-pale-cyan-blue-background-color input[type=submit],
form .site-contents .has-pale-cyan-blue-background-color input[type=submit],
.site-contents .has-pale-cyan-blue-background-color .wp-block-button__link,
.site-contents .has-pale-cyan-blue-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-pale-cyan-blue-background-color .btn,
.site-contents .has-pale-cyan-blue-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-pale-cyan-blue-background-color .button,
.site-contents .has-pale-cyan-blue-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-pale-cyan-blue-background-color input[type=button],
.site-contents .has-pale-cyan-blue-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-pale-cyan-blue-background-color input[type=button],
.site-contents .has-pale-cyan-blue-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-pale-cyan-blue-background-color input[type=submit],
.site-contents .has-pale-cyan-blue-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-pale-cyan-blue-background-color input[type=submit],
.site-contents .has-pale-cyan-blue-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-pale-cyan-blue-background-color .wp-block-button__link,
.site-contents .has-pale-cyan-blue-background-color .wp-block-button__link,
.site-contents .has-vivid-cyan-blue-background-color .btn,
.site-contents .has-vivid-cyan-blue-background-color .button,
.site-contents .has-vivid-cyan-blue-background-color form input[type=button],
form .site-contents .has-vivid-cyan-blue-background-color input[type=button],
.site-contents .has-vivid-cyan-blue-background-color form input[type=submit],
form .site-contents .has-vivid-cyan-blue-background-color input[type=submit],
.site-contents .has-vivid-cyan-blue-background-color .btn,
.site-contents .has-vivid-cyan-blue-background-color .button,
.site-contents .has-vivid-cyan-blue-background-color form input[type=button],
.site-contents form .has-vivid-cyan-blue-background-color input[type=button],
form .site-contents .has-vivid-cyan-blue-background-color input[type=button],
.site-contents .has-vivid-cyan-blue-background-color form input[type=submit],
.site-contents form .has-vivid-cyan-blue-background-color input[type=submit],
form .site-contents .has-vivid-cyan-blue-background-color input[type=submit],
.site-contents .has-vivid-cyan-blue-background-color .wp-block-button__link,
.site-contents .has-vivid-cyan-blue-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-vivid-cyan-blue-background-color .btn,
.site-contents .has-vivid-cyan-blue-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-vivid-cyan-blue-background-color .button,
.site-contents .has-vivid-cyan-blue-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-vivid-cyan-blue-background-color input[type=button],
.site-contents .has-vivid-cyan-blue-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-vivid-cyan-blue-background-color input[type=button],
.site-contents .has-vivid-cyan-blue-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-vivid-cyan-blue-background-color input[type=submit],
.site-contents .has-vivid-cyan-blue-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-vivid-cyan-blue-background-color input[type=submit],
.site-contents .has-vivid-cyan-blue-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-vivid-cyan-blue-background-color .wp-block-button__link,
.site-contents .has-vivid-cyan-blue-background-color .wp-block-button__link,
.site-contents .has-very-light-gray-background-color .btn,
.site-contents .has-very-light-gray-background-color .button,
.site-contents .has-very-light-gray-background-color form input[type=button],
form .site-contents .has-very-light-gray-background-color input[type=button],
.site-contents .has-very-light-gray-background-color form input[type=submit],
form .site-contents .has-very-light-gray-background-color input[type=submit],
.site-contents .has-very-light-gray-background-color .btn,
.site-contents .has-very-light-gray-background-color .button,
.site-contents .has-very-light-gray-background-color form input[type=button],
.site-contents form .has-very-light-gray-background-color input[type=button],
form .site-contents .has-very-light-gray-background-color input[type=button],
.site-contents .has-very-light-gray-background-color form input[type=submit],
.site-contents form .has-very-light-gray-background-color input[type=submit],
form .site-contents .has-very-light-gray-background-color input[type=submit],
.site-contents .has-very-light-gray-background-color .wp-block-button__link,
.site-contents .has-very-light-gray-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-very-light-gray-background-color .btn,
.site-contents .has-very-light-gray-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-very-light-gray-background-color .button,
.site-contents .has-very-light-gray-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-very-light-gray-background-color input[type=button],
.site-contents .has-very-light-gray-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-very-light-gray-background-color input[type=button],
.site-contents .has-very-light-gray-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-very-light-gray-background-color input[type=submit],
.site-contents .has-very-light-gray-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-very-light-gray-background-color input[type=submit],
.site-contents .has-very-light-gray-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-very-light-gray-background-color .wp-block-button__link,
.site-contents .has-very-light-gray-background-color .wp-block-button__link,
.site-contents .has-cyan-bluish-gray-background-color .btn,
.site-contents .has-cyan-bluish-gray-background-color .button,
.site-contents .has-cyan-bluish-gray-background-color form input[type=button],
form .site-contents .has-cyan-bluish-gray-background-color input[type=button],
.site-contents .has-cyan-bluish-gray-background-color form input[type=submit],
form .site-contents .has-cyan-bluish-gray-background-color input[type=submit],
.site-contents .has-cyan-bluish-gray-background-color .btn,
.site-contents .has-cyan-bluish-gray-background-color .button,
.site-contents .has-cyan-bluish-gray-background-color form input[type=button],
.site-contents form .has-cyan-bluish-gray-background-color input[type=button],
form .site-contents .has-cyan-bluish-gray-background-color input[type=button],
.site-contents .has-cyan-bluish-gray-background-color form input[type=submit],
.site-contents form .has-cyan-bluish-gray-background-color input[type=submit],
form .site-contents .has-cyan-bluish-gray-background-color input[type=submit],
.site-contents .has-cyan-bluish-gray-background-color .wp-block-button__link,
.site-contents .has-cyan-bluish-gray-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-cyan-bluish-gray-background-color .btn,
.site-contents .has-cyan-bluish-gray-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-cyan-bluish-gray-background-color .button,
.site-contents .has-cyan-bluish-gray-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-cyan-bluish-gray-background-color input[type=button],
.site-contents .has-cyan-bluish-gray-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-cyan-bluish-gray-background-color input[type=button],
.site-contents .has-cyan-bluish-gray-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-cyan-bluish-gray-background-color input[type=submit],
.site-contents .has-cyan-bluish-gray-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-cyan-bluish-gray-background-color input[type=submit],
.site-contents .has-cyan-bluish-gray-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-cyan-bluish-gray-background-color .wp-block-button__link,
.site-contents .has-cyan-bluish-gray-background-color .wp-block-button__link,
.site-contents .has-very-dark-gray-background-color .btn,
.site-contents .has-very-dark-gray-background-color .button,
.site-contents .has-very-dark-gray-background-color form input[type=button],
form .site-contents .has-very-dark-gray-background-color input[type=button],
.site-contents .has-very-dark-gray-background-color form input[type=submit],
form .site-contents .has-very-dark-gray-background-color input[type=submit],
.site-contents .has-very-dark-gray-background-color .btn,
.site-contents .has-very-dark-gray-background-color .button,
.site-contents .has-very-dark-gray-background-color form input[type=button],
.site-contents form .has-very-dark-gray-background-color input[type=button],
form .site-contents .has-very-dark-gray-background-color input[type=button],
.site-contents .has-very-dark-gray-background-color form input[type=submit],
.site-contents form .has-very-dark-gray-background-color input[type=submit],
form .site-contents .has-very-dark-gray-background-color input[type=submit],
.site-contents .has-very-dark-gray-background-color .wp-block-button__link,
.site-contents .has-very-dark-gray-background-color .editor-writing-flow .btn,
.editor-writing-flow .site-contents .has-very-dark-gray-background-color .btn,
.site-contents .has-very-dark-gray-background-color .editor-writing-flow .button,
.editor-writing-flow .site-contents .has-very-dark-gray-background-color .button,
.site-contents .has-very-dark-gray-background-color .editor-writing-flow form input[type=button],
.editor-writing-flow form .site-contents .has-very-dark-gray-background-color input[type=button],
.site-contents .has-very-dark-gray-background-color form .editor-writing-flow input[type=button],
form .editor-writing-flow .site-contents .has-very-dark-gray-background-color input[type=button],
.site-contents .has-very-dark-gray-background-color .editor-writing-flow form input[type=submit],
.editor-writing-flow form .site-contents .has-very-dark-gray-background-color input[type=submit],
.site-contents .has-very-dark-gray-background-color form .editor-writing-flow input[type=submit],
form .editor-writing-flow .site-contents .has-very-dark-gray-background-color input[type=submit],
.site-contents .has-very-dark-gray-background-color .editor-writing-flow .wp-block-button__link,
.editor-writing-flow .site-contents .has-very-dark-gray-background-color .wp-block-button__link,
.site-contents .has-very-dark-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-pale-pink-background-color .btn,
.editor-writing-flow .has-pale-pink-background-color .button,
.editor-writing-flow .has-pale-pink-background-color form input[type=button],
form .editor-writing-flow .has-pale-pink-background-color input[type=button],
.editor-writing-flow .has-pale-pink-background-color form input[type=submit],
form .editor-writing-flow .has-pale-pink-background-color input[type=submit],
.editor-writing-flow .has-pale-pink-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-pale-pink-background-color .btn,
.editor-writing-flow .has-pale-pink-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-pale-pink-background-color .button,
.editor-writing-flow .has-pale-pink-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-pale-pink-background-color input[type=button],
.editor-writing-flow .has-pale-pink-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-pale-pink-background-color input[type=button],
.editor-writing-flow .has-pale-pink-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-pale-pink-background-color input[type=submit],
.editor-writing-flow .has-pale-pink-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-pale-pink-background-color input[type=submit],
.editor-writing-flow .has-pale-pink-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-pale-pink-background-color .wp-block-button__link,
.editor-writing-flow .has-pale-pink-background-color .btn,
.editor-writing-flow .has-pale-pink-background-color .button,
.editor-writing-flow .has-pale-pink-background-color form input[type=button],
.editor-writing-flow form .has-pale-pink-background-color input[type=button],
form .editor-writing-flow .has-pale-pink-background-color input[type=button],
.editor-writing-flow .has-pale-pink-background-color form input[type=submit],
.editor-writing-flow form .has-pale-pink-background-color input[type=submit],
form .editor-writing-flow .has-pale-pink-background-color input[type=submit],
.editor-writing-flow .has-pale-pink-background-color .wp-block-button__link,
.editor-writing-flow .has-pale-pink-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-red-background-color .btn,
.editor-writing-flow .has-vivid-red-background-color .button,
.editor-writing-flow .has-vivid-red-background-color form input[type=button],
form .editor-writing-flow .has-vivid-red-background-color input[type=button],
.editor-writing-flow .has-vivid-red-background-color form input[type=submit],
form .editor-writing-flow .has-vivid-red-background-color input[type=submit],
.editor-writing-flow .has-vivid-red-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-vivid-red-background-color .btn,
.editor-writing-flow .has-vivid-red-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-vivid-red-background-color .button,
.editor-writing-flow .has-vivid-red-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-vivid-red-background-color input[type=button],
.editor-writing-flow .has-vivid-red-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-vivid-red-background-color input[type=button],
.editor-writing-flow .has-vivid-red-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-vivid-red-background-color input[type=submit],
.editor-writing-flow .has-vivid-red-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-vivid-red-background-color input[type=submit],
.editor-writing-flow .has-vivid-red-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-vivid-red-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-red-background-color .btn,
.editor-writing-flow .has-vivid-red-background-color .button,
.editor-writing-flow .has-vivid-red-background-color form input[type=button],
.editor-writing-flow form .has-vivid-red-background-color input[type=button],
form .editor-writing-flow .has-vivid-red-background-color input[type=button],
.editor-writing-flow .has-vivid-red-background-color form input[type=submit],
.editor-writing-flow form .has-vivid-red-background-color input[type=submit],
form .editor-writing-flow .has-vivid-red-background-color input[type=submit],
.editor-writing-flow .has-vivid-red-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-red-background-color .wp-block-button__link,
.editor-writing-flow .has-luminous-vivid-orange-background-color .btn,
.editor-writing-flow .has-luminous-vivid-orange-background-color .button,
.editor-writing-flow .has-luminous-vivid-orange-background-color form input[type=button],
form .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-orange-background-color form input[type=submit],
form .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-orange-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-luminous-vivid-orange-background-color .btn,
.editor-writing-flow .has-luminous-vivid-orange-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-luminous-vivid-orange-background-color .button,
.editor-writing-flow .has-luminous-vivid-orange-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-orange-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-orange-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-orange-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-orange-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-luminous-vivid-orange-background-color .wp-block-button__link,
.editor-writing-flow .has-luminous-vivid-orange-background-color .btn,
.editor-writing-flow .has-luminous-vivid-orange-background-color .button,
.editor-writing-flow .has-luminous-vivid-orange-background-color form input[type=button],
.editor-writing-flow form .has-luminous-vivid-orange-background-color input[type=button],
form .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-orange-background-color form input[type=submit],
.editor-writing-flow form .has-luminous-vivid-orange-background-color input[type=submit],
form .editor-writing-flow .has-luminous-vivid-orange-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-orange-background-color .wp-block-button__link,
.editor-writing-flow .has-luminous-vivid-orange-background-color .wp-block-button__link,
.editor-writing-flow .has-luminous-vivid-amber-background-color .btn,
.editor-writing-flow .has-luminous-vivid-amber-background-color .button,
.editor-writing-flow .has-luminous-vivid-amber-background-color form input[type=button],
form .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-amber-background-color form input[type=submit],
form .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-amber-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-luminous-vivid-amber-background-color .btn,
.editor-writing-flow .has-luminous-vivid-amber-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-luminous-vivid-amber-background-color .button,
.editor-writing-flow .has-luminous-vivid-amber-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-amber-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-amber-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-amber-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-amber-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-luminous-vivid-amber-background-color .wp-block-button__link,
.editor-writing-flow .has-luminous-vivid-amber-background-color .btn,
.editor-writing-flow .has-luminous-vivid-amber-background-color .button,
.editor-writing-flow .has-luminous-vivid-amber-background-color form input[type=button],
.editor-writing-flow form .has-luminous-vivid-amber-background-color input[type=button],
form .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=button],
.editor-writing-flow .has-luminous-vivid-amber-background-color form input[type=submit],
.editor-writing-flow form .has-luminous-vivid-amber-background-color input[type=submit],
form .editor-writing-flow .has-luminous-vivid-amber-background-color input[type=submit],
.editor-writing-flow .has-luminous-vivid-amber-background-color .wp-block-button__link,
.editor-writing-flow .has-luminous-vivid-amber-background-color .wp-block-button__link,
.editor-writing-flow .has-light-green-cyan-background-color .btn,
.editor-writing-flow .has-light-green-cyan-background-color .button,
.editor-writing-flow .has-light-green-cyan-background-color form input[type=button],
form .editor-writing-flow .has-light-green-cyan-background-color input[type=button],
.editor-writing-flow .has-light-green-cyan-background-color form input[type=submit],
form .editor-writing-flow .has-light-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-light-green-cyan-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-light-green-cyan-background-color .btn,
.editor-writing-flow .has-light-green-cyan-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-light-green-cyan-background-color .button,
.editor-writing-flow .has-light-green-cyan-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-light-green-cyan-background-color input[type=button],
.editor-writing-flow .has-light-green-cyan-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-light-green-cyan-background-color input[type=button],
.editor-writing-flow .has-light-green-cyan-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-light-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-light-green-cyan-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-light-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-light-green-cyan-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-light-green-cyan-background-color .wp-block-button__link,
.editor-writing-flow .has-light-green-cyan-background-color .btn,
.editor-writing-flow .has-light-green-cyan-background-color .button,
.editor-writing-flow .has-light-green-cyan-background-color form input[type=button],
.editor-writing-flow form .has-light-green-cyan-background-color input[type=button],
form .editor-writing-flow .has-light-green-cyan-background-color input[type=button],
.editor-writing-flow .has-light-green-cyan-background-color form input[type=submit],
.editor-writing-flow form .has-light-green-cyan-background-color input[type=submit],
form .editor-writing-flow .has-light-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-light-green-cyan-background-color .wp-block-button__link,
.editor-writing-flow .has-light-green-cyan-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-green-cyan-background-color .btn,
.editor-writing-flow .has-vivid-green-cyan-background-color .button,
.editor-writing-flow .has-vivid-green-cyan-background-color form input[type=button],
form .editor-writing-flow .has-vivid-green-cyan-background-color input[type=button],
.editor-writing-flow .has-vivid-green-cyan-background-color form input[type=submit],
form .editor-writing-flow .has-vivid-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-vivid-green-cyan-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-vivid-green-cyan-background-color .btn,
.editor-writing-flow .has-vivid-green-cyan-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-vivid-green-cyan-background-color .button,
.editor-writing-flow .has-vivid-green-cyan-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-vivid-green-cyan-background-color input[type=button],
.editor-writing-flow .has-vivid-green-cyan-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-vivid-green-cyan-background-color input[type=button],
.editor-writing-flow .has-vivid-green-cyan-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-vivid-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-vivid-green-cyan-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-vivid-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-vivid-green-cyan-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-vivid-green-cyan-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-green-cyan-background-color .btn,
.editor-writing-flow .has-vivid-green-cyan-background-color .button,
.editor-writing-flow .has-vivid-green-cyan-background-color form input[type=button],
.editor-writing-flow form .has-vivid-green-cyan-background-color input[type=button],
form .editor-writing-flow .has-vivid-green-cyan-background-color input[type=button],
.editor-writing-flow .has-vivid-green-cyan-background-color form input[type=submit],
.editor-writing-flow form .has-vivid-green-cyan-background-color input[type=submit],
form .editor-writing-flow .has-vivid-green-cyan-background-color input[type=submit],
.editor-writing-flow .has-vivid-green-cyan-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-green-cyan-background-color .wp-block-button__link,
.editor-writing-flow .has-pale-cyan-blue-background-color .btn,
.editor-writing-flow .has-pale-cyan-blue-background-color .button,
.editor-writing-flow .has-pale-cyan-blue-background-color form input[type=button],
form .editor-writing-flow .has-pale-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-pale-cyan-blue-background-color form input[type=submit],
form .editor-writing-flow .has-pale-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-pale-cyan-blue-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-pale-cyan-blue-background-color .btn,
.editor-writing-flow .has-pale-cyan-blue-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-pale-cyan-blue-background-color .button,
.editor-writing-flow .has-pale-cyan-blue-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-pale-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-pale-cyan-blue-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-pale-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-pale-cyan-blue-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-pale-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-pale-cyan-blue-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-pale-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-pale-cyan-blue-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-pale-cyan-blue-background-color .wp-block-button__link,
.editor-writing-flow .has-pale-cyan-blue-background-color .btn,
.editor-writing-flow .has-pale-cyan-blue-background-color .button,
.editor-writing-flow .has-pale-cyan-blue-background-color form input[type=button],
.editor-writing-flow form .has-pale-cyan-blue-background-color input[type=button],
form .editor-writing-flow .has-pale-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-pale-cyan-blue-background-color form input[type=submit],
.editor-writing-flow form .has-pale-cyan-blue-background-color input[type=submit],
form .editor-writing-flow .has-pale-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-pale-cyan-blue-background-color .wp-block-button__link,
.editor-writing-flow .has-pale-cyan-blue-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-cyan-blue-background-color .btn,
.editor-writing-flow .has-vivid-cyan-blue-background-color .button,
.editor-writing-flow .has-vivid-cyan-blue-background-color form input[type=button],
form .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-vivid-cyan-blue-background-color form input[type=submit],
form .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-vivid-cyan-blue-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-vivid-cyan-blue-background-color .btn,
.editor-writing-flow .has-vivid-cyan-blue-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-vivid-cyan-blue-background-color .button,
.editor-writing-flow .has-vivid-cyan-blue-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-vivid-cyan-blue-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-vivid-cyan-blue-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-vivid-cyan-blue-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-vivid-cyan-blue-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-vivid-cyan-blue-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-cyan-blue-background-color .btn,
.editor-writing-flow .has-vivid-cyan-blue-background-color .button,
.editor-writing-flow .has-vivid-cyan-blue-background-color form input[type=button],
.editor-writing-flow form .has-vivid-cyan-blue-background-color input[type=button],
form .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=button],
.editor-writing-flow .has-vivid-cyan-blue-background-color form input[type=submit],
.editor-writing-flow form .has-vivid-cyan-blue-background-color input[type=submit],
form .editor-writing-flow .has-vivid-cyan-blue-background-color input[type=submit],
.editor-writing-flow .has-vivid-cyan-blue-background-color .wp-block-button__link,
.editor-writing-flow .has-vivid-cyan-blue-background-color .wp-block-button__link,
.editor-writing-flow .has-very-light-gray-background-color .btn,
.editor-writing-flow .has-very-light-gray-background-color .button,
.editor-writing-flow .has-very-light-gray-background-color form input[type=button],
form .editor-writing-flow .has-very-light-gray-background-color input[type=button],
.editor-writing-flow .has-very-light-gray-background-color form input[type=submit],
form .editor-writing-flow .has-very-light-gray-background-color input[type=submit],
.editor-writing-flow .has-very-light-gray-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-very-light-gray-background-color .btn,
.editor-writing-flow .has-very-light-gray-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-very-light-gray-background-color .button,
.editor-writing-flow .has-very-light-gray-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-very-light-gray-background-color input[type=button],
.editor-writing-flow .has-very-light-gray-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-very-light-gray-background-color input[type=button],
.editor-writing-flow .has-very-light-gray-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-very-light-gray-background-color input[type=submit],
.editor-writing-flow .has-very-light-gray-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-very-light-gray-background-color input[type=submit],
.editor-writing-flow .has-very-light-gray-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-very-light-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-very-light-gray-background-color .btn,
.editor-writing-flow .has-very-light-gray-background-color .button,
.editor-writing-flow .has-very-light-gray-background-color form input[type=button],
.editor-writing-flow form .has-very-light-gray-background-color input[type=button],
form .editor-writing-flow .has-very-light-gray-background-color input[type=button],
.editor-writing-flow .has-very-light-gray-background-color form input[type=submit],
.editor-writing-flow form .has-very-light-gray-background-color input[type=submit],
form .editor-writing-flow .has-very-light-gray-background-color input[type=submit],
.editor-writing-flow .has-very-light-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-very-light-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-cyan-bluish-gray-background-color .btn,
.editor-writing-flow .has-cyan-bluish-gray-background-color .button,
.editor-writing-flow .has-cyan-bluish-gray-background-color form input[type=button],
form .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=button],
.editor-writing-flow .has-cyan-bluish-gray-background-color form input[type=submit],
form .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=submit],
.editor-writing-flow .has-cyan-bluish-gray-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-cyan-bluish-gray-background-color .btn,
.editor-writing-flow .has-cyan-bluish-gray-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-cyan-bluish-gray-background-color .button,
.editor-writing-flow .has-cyan-bluish-gray-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=button],
.editor-writing-flow .has-cyan-bluish-gray-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=button],
.editor-writing-flow .has-cyan-bluish-gray-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=submit],
.editor-writing-flow .has-cyan-bluish-gray-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=submit],
.editor-writing-flow .has-cyan-bluish-gray-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-cyan-bluish-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-cyan-bluish-gray-background-color .btn,
.editor-writing-flow .has-cyan-bluish-gray-background-color .button,
.editor-writing-flow .has-cyan-bluish-gray-background-color form input[type=button],
.editor-writing-flow form .has-cyan-bluish-gray-background-color input[type=button],
form .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=button],
.editor-writing-flow .has-cyan-bluish-gray-background-color form input[type=submit],
.editor-writing-flow form .has-cyan-bluish-gray-background-color input[type=submit],
form .editor-writing-flow .has-cyan-bluish-gray-background-color input[type=submit],
.editor-writing-flow .has-cyan-bluish-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-cyan-bluish-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-very-dark-gray-background-color .btn,
.editor-writing-flow .has-very-dark-gray-background-color .button,
.editor-writing-flow .has-very-dark-gray-background-color form input[type=button],
form .editor-writing-flow .has-very-dark-gray-background-color input[type=button],
.editor-writing-flow .has-very-dark-gray-background-color form input[type=submit],
form .editor-writing-flow .has-very-dark-gray-background-color input[type=submit],
.editor-writing-flow .has-very-dark-gray-background-color .site-contents .btn,
.site-contents .editor-writing-flow .has-very-dark-gray-background-color .btn,
.editor-writing-flow .has-very-dark-gray-background-color .site-contents .button,
.site-contents .editor-writing-flow .has-very-dark-gray-background-color .button,
.editor-writing-flow .has-very-dark-gray-background-color .site-contents form input[type=button],
.site-contents form .editor-writing-flow .has-very-dark-gray-background-color input[type=button],
.editor-writing-flow .has-very-dark-gray-background-color form .site-contents input[type=button],
form .site-contents .editor-writing-flow .has-very-dark-gray-background-color input[type=button],
.editor-writing-flow .has-very-dark-gray-background-color .site-contents form input[type=submit],
.site-contents form .editor-writing-flow .has-very-dark-gray-background-color input[type=submit],
.editor-writing-flow .has-very-dark-gray-background-color form .site-contents input[type=submit],
form .site-contents .editor-writing-flow .has-very-dark-gray-background-color input[type=submit],
.editor-writing-flow .has-very-dark-gray-background-color .site-contents .wp-block-button__link,
.site-contents .editor-writing-flow .has-very-dark-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-very-dark-gray-background-color .btn,
.editor-writing-flow .has-very-dark-gray-background-color .button,
.editor-writing-flow .has-very-dark-gray-background-color form input[type=button],
.editor-writing-flow form .has-very-dark-gray-background-color input[type=button],
form .editor-writing-flow .has-very-dark-gray-background-color input[type=button],
.editor-writing-flow .has-very-dark-gray-background-color form input[type=submit],
.editor-writing-flow form .has-very-dark-gray-background-color input[type=submit],
form .editor-writing-flow .has-very-dark-gray-background-color input[type=submit],
.editor-writing-flow .has-very-dark-gray-background-color .wp-block-button__link,
.editor-writing-flow .has-very-dark-gray-background-color .wp-block-button__link {
  background-color: #e5f8f2;
  color: #199482;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.home .page-contents > p {
  margin: 0;
}

.home-cover-container {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding: 5em 1em 1em;
}

.home-cover {
  position: relative;
}
.home-cover p {
  margin: 0;
}
.home-cover img {
  width: 100%;
  height: calc(100vh - 8em);
  min-height: 600px;
  max-height: 56.25vw;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home-cover img {
    max-height: 75vw;
    min-height: 400px;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home-cover img {
    max-height: 400px;
    min-height: 100vw;
  }
}
.home-cover__content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  bottom: 8vh;
  left: 8vw;
  color: #fff;
}
@media print, screen and (max-width: 39.99875em) {
  .home-cover__content {
    left: 4vw;
    bottom: 5em;
  }
}
.home-cover__copy {
  position: relative;
  padding-left: 1em;
  padding-right: 0.5em;
  padding-bottom: 0.125em;
  white-space: nowrap;
  line-height: 1.25;
  font-size: 3vw;
  background-color: rgba(43, 183, 147, 0.7);
}
@media print, screen and (max-width: 39.99875em) {
  .home-cover__copy {
    font-size: 4.25vw;
  }
}
.home-cover__copy:before {
  content: "";
  position: absolute;
  background: url(../images/icon_quote.svg) no-repeat;
  background-size: contain;
  width: 1em;
  height: 1em;
  top: -10px;
  left: -0.25em;
}
.home-cover__graduate {
  align-self: flex-start;
  background-color: rgba(43, 183, 147, 0.7);
  padding: 0 1.5rem 0.75em;
  line-height: 1;
  font-size: 0.75rem;
}

.page-contents .home-event-news-headline {
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: -4em auto 1.5em;
  padding: 1em 1em 1em 2em;
  max-width: 30em;
  background-color: #fff;
}
.page-contents .home-event-news-headline .wp-block-column {
  flex: 0 0 auto;
  width: auto;
  margin-bottom: 0;
}
.page-contents .home-event-news-headline h2 {
  margin: 0;
  padding: 0;
  font-size: 0.8125rem;
  color: #626E6C;
}
.page-contents .home-event-news-headline h2:before {
  content: "Event & News";
  position: static;
  width: auto;
  margin-right: 0.25em;
  border: none;
  background-color: transparent;
  font-size: 1.875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .page-contents .home-event-news-headline h2:before {
    display: block;
    margin-bottom: -0.375em;
  }
}
.page-contents .home-event-news-headline .link-to-list {
  margin: 0;
}
.page-contents .home-event-news-headline .link-to-list a {
  background-color: #fff;
  box-shadow: 0 0 0 transparent;
  color: #09ae96;
}
.page-contents .home-event-news-headline .link-to-list a:after {
  content: "▻";
  margin-left: 0.5em;
  vertical-align: -0.125em;
  font-family: "SSCommunity";
}

.home-event-news-feed {
  margin-bottom: 2em;
}

.home-program-headline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 600px;
  padding: 3em;
}
@media print, screen and (max-width: 63.99875em) {
  .home-program-headline {
    background-position: center bottom !important;
  }
}
.home-program-headline .wp-block-column h2 {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0;
}
.home-program-headline .wp-block-column h2:before {
  display: none;
}
.home-program-headline .wp-block-column h2,
.home-program-headline .wp-block-column > p.has-text-color {
  box-shadow: 0px 0px 0px 3px #fff;
  background-color: #fff;
}
.home-program-headline .wp-block-column > p.has-text-color {
  display: inline;
  line-height: 1.5;
}
.home-program-headline .wp-block-column {
  text-align: center;
}
@media print, screen and (min-width: 40em) {
  .home-program-headline .wp-block-column {
    max-width: 33.333%;
  }
}
.home-program-headline ~ .section-3programs {
  position: relative;
}
.home-program-headline ~ .section-3programs:before {
  z-index: -1;
}
.home-program-headline ~ .section-3programs:before {
  background-color: #fff;
}
@media print, screen and (min-width: 64em) {
  .home-program-headline ~ .section-3programs {
    margin-top: -500px;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .home-program-headline ~ .section-3programs {
    margin-top: -22em;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .home-program-headline ~ .section-3programs {
    margin-top: -18em;
  }
}

.post__date, .thumb-post__date {
  position: absolute;
  padding: 0 0.25em;
  background-color: #ea7900;
  color: #fff;
}

.thumb-post {
  position: relative;
  display: flex;
  margin-bottom: 1em;
  border-right: 2px solid #09ae96;
  border-bottom: 2px solid #e6eaea;
}
.thumb-post.-text {
  align-items: center;
}
.thumb-post__header {
  position: relative;
  width: 25%;
  min-width: 8em;
  margin-right: 1em;
}
.-text .thumb-post__header {
  width: auto;
  min-width: 0;
}
.-text .thumb-post__date {
  position: static;
  font-size: calc(0.2083333333vw + 13.3333333333px);
}
.thumb-post__image {
  width: 100%;
  height: 7em;
}
.thumb-post__content {
  display: flex;
  flex-direction: column-reverse;
  flex: 1 1 auto;
  width: 50%;
  padding-top: 0.75em;
  padding-bottom: 0.5em;
}
.thumb-post__copy, .thumb-post__status {
  margin: 0;
}
.thumb-post__status {
  line-height: 1;
  font-size: calc(0.2083333333vw + 11.3333333333px);
}
.page-contents .thumb-post__title {
  margin: 0 0 0.125em;
  padding: 0;
  border: none;
}
.thumb-post a:after {
  z-index: -1;
}
.thumb-post a:hover:after {
  background-color: #e6eaea;
}
.thumb-post a:focus:before {
  outline: #0080A7 auto 5px;
}

.post {
  position: relative;
}
.post__date {
  top: -1em;
  left: -1em;
}
.post__title {
  display: inline-block;
  position: relative;
  margin-top: 0;
  margin-bottom: 1.75em;
  padding-top: 0.25em;
}
.post__title:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0.125em;
  left: 0;
  right: 0;
  height: 0.125em;
  border-radius: 0.0625em;
  background-color: #ea7900;
}
.post__title:after {
  content: "\a";
  white-space: pre;
}
.post__sub-title {
  margin-left: 1em;
}
.post__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 4em;
  padding-top: 1em;
  border-top: 1px solid #7B8987;
}
.post__nav {
  background-color: #09ae96;
}
.post__nav-lists {
  display: flex;
  padding: 2.5em;
  justify-content: space-around;
  margin: 4em 0 -4em;
}
.post__nav-lists a {
  display: inline-block;
  padding: 1.5em;
  color: #fff;
}
.post__nav-lists .prev {
  order: 1;
}
.post__nav-lists .next {
  order: 3;
}
.post__nav-lists .to-list {
  order: 2;
}
@media print, screen and (max-width: 39.99875em) {
  .post__nav-lists {
    padding: 1.5em 0;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .post__nav-lists .to-list span {
    display: none;
  }
}

.post--scholar__graduate {
  margin-top: -3em;
}

.wp-block-separator ~ .section-3programs, .home-program-headline {
  background: #fff url("../images/illust-tree.svg") no-repeat;
  background-size: auto 125%;
  background-position: center;
}

.section-3programs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 500px;
  padding: 2em;
}
@media print, screen and (min-width: 40em) {
  .section-3programs {
    height: 500px;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .section-3programs {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}
.wp-block-separator ~ .section-3programs {
  margin-bottom: -4em;
}
.section-3programs .wp-block-column h3 {
  color: #199482;
}
.section-3programs > .wp-block-column {
  position: relative;
  max-width: 392px;
  background-color: #ceeee7;
}
@media print, screen and (min-width: 40em) {
  .section-3programs > .wp-block-column:nth-of-type(2) {
    order: 3;
  }
  .section-3programs > .wp-block-column:nth-of-type(3) {
    order: 2;
    align-self: flex-end;
  }
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-3programs > .wp-block-column {
    max-width: 33%;
    align-self: flex-start;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .section-3programs > .wp-block-column {
    width: 100%;
    margin-bottom: 1em;
  }
}
.section-3programs .wp-block-columns {
  flex-direction: row;
  background-color: #e5f8f2;
}
.section-3programs .wp-block-columns .wp-block-column {
  position: relative;
  flex: 1 1 auto;
  width: calc(100% - 10em);
  padding: 0.5em 1em;
}
.section-3programs .wp-block-columns .wp-block-column p {
  margin: 2em 0 1em;
  line-height: 1.25;
}
.section-3programs .wp-block-columns .wp-block-column p:first-child {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0.25em 1em;
  white-space: nowrap;
}
.section-3programs .wp-block-columns .wp-block-column:last-of-type {
  flex: 0 0 auto;
  align-self: flex-end;
  width: inherit;
  padding-left: 0;
  padding-bottom: 1em;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .section-3programs .wp-block-column h3:before {
    margin-top: 0.25em;
  }
  .section-3programs .wp-block-column h3 strong {
    clear: left;
  }
  .section-3programs .wp-block-button {
    max-width: 5em;
    margin: 0;
  }
}

.section-3programs h3,
.headline-program--scholar,
.headline-program--pal,
.headline-program--school {
  margin: 0;
  padding: 0.75em 0.5em;
  border: none;
  font-size: 1.6875rem;
}
.section-3programs h3 strong,
.headline-program--scholar strong,
.headline-program--pal strong,
.headline-program--school strong {
  display: block;
  margin-top: 0.25em;
  font-size: 1.125rem;
}
.section-3programs h3:before,
.headline-program--scholar:before,
.headline-program--pal:before,
.headline-program--school:before {
  float: left;
  margin-right: 0.375em;
  margin-top: 0.05em;
  padding: 0.25em;
  font-family: "SSCommunity";
  font-weight: normal;
  font-size: 2.375rem;
}

.headline-program--scholar:before {
  content: "\e575";
}

.headline-program--pal:before {
  content: "\e311";
}

.headline-program--school:before {
  content: "👍";
}

@media print, screen and (min-width: 40em) {
  .section-3program .wp-block-column:first-of-type svg {
    width: calc(100% + 24vw);
    height: 64vw;
    margin-left: -16vw;
    margin-right: -8vw;
  }
}
.section-3program .wp-block-column:last-of-type {
  position: relative;
  z-index: 1;
  padding: 1.5em;
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .section-3program .wp-block-column:last-of-type {
    padding: 0;
    padding-left: 1em;
  }
}
.section-3program h3 {
  margin-top: 1em;
  padding: 0.5em 0;
  font-size: calc(0.625vw + 26px);
  color: #199482;
}
.section-3program h3:before {
  display: block;
  line-height: 1em;
  margin-right: 0.125em;
}
.section-3program h3 strong {
  font-size: calc(0.2083333333vw + 13.3333333333px);
}
.section-3program p {
  margin-bottom: 0;
  font-size: calc(0.2083333333vw + 11.3333333333px);
}
.section-3program .wp-block-button {
  text-align: right;
}

@media print, screen and (min-width: 40em) {
  .js-wpv-view-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .js-wpv-view-layout .thumb-post {
    width: calc(50% - 1em);
  }
  .js-wpv-view-layout .thumb-post:first-of-type:last-of-type {
    width: 100%;
  }
}

.logo-do-it--wide {
  padding-top: 0.25em;
  padding-bottom: 0.5em;
}

p {
  text-align: justify;
}
.has-3-columns p, .has-4-columns p, .has-5-columns p {
  text-align: left;
}