*{
  box-sizing:border-box;
}

*{ //ios 子要素でも滑らかなスクロールを実現させる
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0; padding: 0;
}

a,p{
  word-wrap: break-word;
}

main{
	display: block;
}

figure {
  margin: 0;
}

address{
  font-style: normal;
}

img{
  max-width: 100%;
}

video{
  width: 100%;
}

.no-bullet{
  margin: 0; padding: 0;
  list-style-type: none;
}

%ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%anchor-wrap{
  content: '';
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
}

.align-middle{
  align-items: center;
}
