.wp-block-columns{
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: -0.5em;
  margin-right: -0.5em;
  @include breakpoint(small down){
    flex-direction: column;
  }
  .wp-block-column{
    width: 100%;
    flex: 0 1 auto;
    margin-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1em;
    @include breakpoint(small down){
      padding-left: 0;
      padding-right: 0;
    }
  }
}

  .wp-block-column{
  @include breakpoint(medium up){
    .has-3-columns &{ width: calc(33.333% - 0.5em); }
    .has-4-columns &{ width: calc(25% - 0.5em); }
  }
}
