.section-about.bg-image {
  padding: 2em 0 0;
  @include breakpoint(large up){
    justify-content: space-around;
  };
  @include breakpoint(medium up){
    padding: 0 4em;
  };
  .wp-block-media-text__content{
    max-width: 46em;
    padding: 2em;
    background-color: rgba($white, 0.92);
    @include breakpoint(large up){
      width: calc(75% - 8em);
      padding: 3em 4em 2.5em;
    };
    @include breakpoint(medium only){
      width: 100%;
      padding: 2.5em 3em 2em;
    };
  }
  .wp-block-media-text__media img{
    height: 100%;
  }
}

.section-support{
  .wp-block-column{
    display: flex;
    flex-direction: column;
    h4{
      margin: 0 0 0.5em;
    }
  }
}
.thumb-cover{
  order: -1;
  height: 12em;
  margin-bottom: 1em;
  img{ width: 100%; height: 100%; }
}
