%post-date{
  @extend %en-condensed-font;
  position: absolute;
  padding: 0 0.25em;
  background-color: $yellow;
  color: $white;
}

.thumb-post{
  &{
    position: relative;
    display: flex;
    margin-bottom: 1em;
    border-right: 2px solid $emerald-green;
    border-bottom: 2px solid $pale-gray;
    &.-text{
      align-items: center;
    }
  }
  &__header{
    position: relative;
    width: 25%;
    min-width: 8em;
    margin-right: 1em;
    .-text &{
      width: auto;
      min-width: 0;
    }
  }
  &__date{
    @extend %post-date;
    .-text &{
      position: static;
      font-size: font-size(base);
    }
  }
  &__image{
    width: 100%; height: 7em;
  }
  &__content{
    display: flex;
    flex-direction: column-reverse;
    flex: 1 1 auto;
    width: 50%;
    padding-top: 0.75em;
    padding-bottom: 0.5em;
  }
  &__copy, &__status{ margin: 0; }
  &__copy{ @extend .has-medium-font-size; }
  &__status{
    line-height: 1;
    font-size: font-size(small);
  }
  .page-contents &__title{
    @extend %ellipsis;
    @extend .has-normal-font-size;
    margin: 0 0 0.125em;
    padding: 0;
    border: none;
  }
  a:before, a:after{ @extend %anchor-wrap; }
  a:after{ z-index: -1; }
  a:hover:after{ background-color: $pale-gray; }
  a:focus:before{
    outline: $blue auto 5px
  }
}

.post{
  position: relative;
  &__date{
    @extend %post-date;
    top: -1em; left: -1em;
  }
  &__title{
    @extend .has-huge-font-size;
    display: inline-block;
    position: relative;
    margin-top: 0;
    margin-bottom: 1.75em;
    padding-top: 0.25em;
    &:before{
      content: '';
      position: absolute; z-index: -1;
      top: -0.125em;
      left: 0; right: 0;
      height: 0.125em;
      border-radius: (0.125em / 2);
      background-color: $yellow;
    }
    &:after{
      content: '\A';
      white-space: pre;
    }
  }
  &__sub-title{
    margin-left: 1em;
  }
  &__footer{
    display: flex;
    justify-content: space-between;
    margin-top: 4em;
    padding-top: 1em;
    border-top: 1px solid $light-gray;
  }
  &__nav{
    background-color: $emerald-green;
  }
  &__nav-lists{
    @extend .no-bullet;
    display: flex;
    padding: 2.5em;
    justify-content: space-around;
    margin: 4em 0 -4em;
    a{
      display: inline-block;
      padding: 1.5em;
      color: $white;
    }
    .prev{ order: 1; }
    .next{ order: 3; }
    .to-list{ order: 2; }
    @include breakpoint(small down){ padding: 1.5em 0; };
    @include breakpoint(small down){ .to-list span{ display: none; } };
  }
}

.post--scholar__graduate{
  margin-top: -3em;
}
