@import "node_modules/tiny-slider/src/tiny-slider.scss";

.home .page-contents > p{ margin: 0; }

.home-cover-container{
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding: 5em 1em 1em;
}

.home-cover{
  position: relative;
  p{ margin: 0; }
  img{
    width: 100%;
    height: calc(100vh - 8em);
    min-height: 600px;
    max-height: 56.25vw;
    @include breakpoint(medium only){
      max-height: 75vw;
      min-height: 400px;
    };
    @include breakpoint(small down){
      max-height: 400px;
      min-height: 100vw;
    };
  }
  a:before{ @extend %anchor-wrap; }
  &__content{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    position: absolute;
    top: 0; bottom: 8vh;
    left: 8vw;
    @include breakpoint(small down){
      left: 4vw; bottom: 5em;
    };
    color: $white;
  }
  &__copy{
    position: relative;
    padding-left: 1em;
    padding-right: 0.5em;
    padding-bottom: 0.125em;
    white-space: nowrap;
    line-height: 1.25;
    font-size: 3vw;
    background-color: rgba($bibit-green, 0.7);
    @include breakpoint(small down){
      font-size: 4.25vw;
    };
    &:before{
      content: '';
      position: absolute;
      background: url(../images/icon_quote.svg) no-repeat;
      background-size: contain;
      width: 1em; height: 1em;
      top: -10px; left: -0.25em;
    }
  }
  &__graduate{
    align-self: flex-start;
    background-color: rgba($bibit-green, 0.7);
    padding: 0 rem-calc(24) 0.75em;
    line-height: 1;
    font-size: rem-calc(12);
  }
}

.page-contents .home-event-news-headline{
  &{
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: -4em auto 1.5em;
    padding: 1em 1em 1em 2em;
    max-width: 30em;
    background-color: $white;
  }
  .wp-block-column{
    flex: 0 0 auto;
    width: auto;
    margin-bottom: 0;
  }
  h2{
    &:before{
      @extend %en-condensed-font;
      content: 'Event & News';
      position: static;
      width: auto;
      margin-right: 0.25em;
      border: none;
      background-color: transparent;
      font-size: rem-calc(30);
      @include breakpoint(small down){
        display: block;
        margin-bottom: -0.375em;
      };
    }
    margin: 0; padding: 0;
    font-size: rem-calc(13);
    color: $gray;
  }
  .link-to-list{
    margin: 0;
    a{
      background-color: $white;
      box-shadow: 0 0 0 transparent;
      color: $emerald-green;
      &:after{
        content: '▻';
        margin-left: 0.5em;
        vertical-align: -0.125em;
        font-family: $icon-font;
      }
    }
  }
}

.home-event-news-feed{
  margin-bottom: 2em;
}
.home-program-headline{
  @extend .alignfull;
  @extend %magenta-tree;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 600px;
  padding: 3em;
  @include breakpoint(medium down){
    background-position: center bottom !important;
  };
  .wp-block-column h2{
    display: inline-block;
    margin: 0 0 0.25em;
    padding: 0;
    &:before{ display: none; }
  }
  .wp-block-column h2,
  .wp-block-column > p.has-text-color{
    box-shadow: 0px 0px 0px 3px $white;
    background-color: $white;
  }
  .wp-block-column > p.has-text-color{
    display: inline;
    line-height: 1.5;
  }
  .wp-block-column{
    text-align: center;
    @include breakpoint(medium up){
      max-width: 33.333%;
    };
  }
  & ~ .section-3programs{
    position: relative;
    &:before{
      @extend %anchor-wrap;
      z-index: -1;
    }
    &:before{ background-color: $white; }
    @include breakpoint(large up){
      margin-top: -500px;
    };
    @include breakpoint(medium only){
      margin-top: -22em;
    };
    @include breakpoint(small down){
      margin-top: -18em;
    };
  }
}
