// ページ共通ヘッダー

.page-header{
  position: relative;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-bottom: 3em;
  padding: 1em;
  &__title-wraper{
    position: absolute;
    bottom: 0; right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: calc(100% - 2em);
    padding: 1em;
    padding-left: 2em;
    background-color: $white;
    color: $gray;
  }
}

%cover-height{
  height: calc(90vh - 80px);
  max-height: 540px;
  @include breakpoint(medium only){ max-height: 430px; };
  @include breakpoint(small down){ max-height: 320px; };
}
.page-cover{
  img{
    @extend %cover-height;
    width: 100%;
  }
}

.page-title{
  margin: 0 0.5em 0 0;
  font-size: font-size(xlarge);
  line-height: 1.25;
  .en{
    @extend %en-condensed-font;
    text-transform: capitalize;
    font-size: rem-calc(50);
  }
}
