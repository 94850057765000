.menu-item-has-children a:focus ~ .sub-menu,
.menu-item:hover .sub-menu,
.sub-menu.focus{
  display: block;
  transform: translateY(0);
  opacity: 1;
  left: -1em;
}
.sub-menu{
  transition:
    opacity 0.5s ease(out-quint),
    left 0.5s ease(out-quint);
  position: absolute; z-index: 64;
  transform: translateY(calc(-100% - 2em));
  top: 100%; left: -2em;
  opacity: 0;
  margin: 0; padding: 1em;
  background-color: $white;
  list-style: none;
  li{
    white-space: nowrap;
  }
  @include breakpoint(medium down){
    display: none;
    transform: translateY(-100vh);
    box-shadow:0px 0px 50px -8px rgba($black, 0.5);
    a{ line-height: 3em; }
    .menu-open &{ display: block; }
  }
}
.global-nav{
  @include breakpoint(medium down){
    transition:
      background-color 0.1s linear;
    .menu-open &{
      top: 0;
      height: 100%;
      background-color: rgba($gray, 0.5);
    }
    position: fixed; z-index: 256;
    top: 100%;
    width: 100%; height: 0;
    background-color: rgba($gray, 0);
    &__lists{
      transition:
        transform 0.4s ease(out-quint) 0.1s,
        opacity 0.4s linear 0.1s !important;
      transform: scale(1.1);
      opacity: 0;
      position: fixed; z-index: 255;
      top: 100vh;
      left: 1em; right: 1em;
      flex-direction: column;
      padding: 2em 1.75em 0em;
      .menu-open &{
        transform: scale(1);
        opacity: 1;
        top: 1em; bottom: 1em;
      }
      .menu-item{
        width: 100%;
        font-size: font-size(large);
        &:before{
          margin-right: 1em;
          font-family: $icon-font;
          vertical-align: -0.125em;
        }
        &:before{ content: '▶'; }
        &.menu-item-has-children:before{ content: '+'; }
      }
    }
  }
  &__lists{
    @extend .no-bullet;
    transition: height 0.5s ease(out-quint);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    a{ color: $gray-green; }
    .wp-logged-in &{ margin-top: 32px; }
    @include breakpoint(large up){
      position: fixed; z-index: 128;
      top: 0;
      left: 0; right: 0;
      height: 5em;
      .page-header-out &{ height: 4em; }
      &.focus{
        z-index: 256;
      }
    }
    a{
      z-index: 1;
      display: inline-block;
      padding: 0.5em;
      @media screen and (max-width: 68em){
        letter-spacing: -0.0125em;
        font-size: rem-calc(15);
      }
      &:hover{
        background-color: $emerald-green;
        color: $white;
      }
    }
  }
  .menu-item-has-children li a{ padding-left: 1em; padding-right: 1em; }
  .current-menu-parent,
  .current-menu-item{
    & > a{ border-bottom: 2px solid $emerald-green; }
    .current-menu-item > a{
      color: $emerald-green-text;
      border-bottom: none;
      &:hover{ color: $white; }
    }
  }
  .menu-item{
    position: relative;
    order: 1;
  }
  &__menu{
    position: fixed; z-index: 256;
    top: 0; right: 0;
    background-color: $white;
    @include breakpoint(large up){
      display: none;
    };
  }
}
.global-nav{

  &__menu-label,
  &__list--lang{ width: 62px; }
  &__list--home{ order: 0; width: 186px; }
  &__list--lang{ order: 9; visibility: hidden; }

  &__menu-label,
  &__list--home,
  &__list--lang{
    cursor: pointer;
    appearance: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 62px;
    background-color: $white;
    border: none;
    line-height: 1;
    &.ss-icon:before{
      display: block;
      margin: 0 !important;
      font-size: rem-calc(23);
    }
    a, span{
      @extend %en-font-bold;
      font-size: rem-calc(11);
      &:before{ @extend %anchor-wrap; }
    }
  }
}
html[lang='ja'] .ss-icon{
  &:before{ margin-right: 0.25em; vertical-align: -0.125em; }
  &:after{ margin-left: 0.25em; vertical-align: -0.125em; }
}


.footer-nav{
  &{
    align-self: flex-end;
  }
  &__lists{
    @extend .no-bullet;
    display: flex;
    align-items: center;
    @include breakpoint(small down){
      flex-direction: column;
      align-items: flex-end;
    };
  }
  a{
    display: inline-block;
    padding: 0 1em;
    line-height: 3em;
  }
  .ss-icon a{
    padding: 0 0.5em;
    text-decoration: none;
    line-height: 2.5em;
    font-size: rem-calc(24);
  }
}

.nav-links{
  display: flex;
  align-items: center;
  justify-content: center;
  .page-numbers{
    padding: 1em;
  }
  .current{

  }
}
