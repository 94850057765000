$white: #fff;

$black: #44504E;
$true-black: #000;
$neutral-gray: #dddddd;
$pale-gray: #e6eaea;
$light-gray: #7B8987;
$gray: #626E6C;
$gray-green: #525F5D;
$red: #d60808;
$emerald-green-text: #199482;
$emerald-green: #09ae96;
$bibit-green: #2bb793;
$light-green: #ceeee7;
$pale-green: #e5f8f2;
$cyan: #00A0D1;
$twitter-blue: #1DA1EF;
$deep-blue: #009390;
$blue: #0080A7;
$magenta: #932275;
$light-magenta: #EADBE6;
$pink: #D96ABB;
$pale-pink: #F5F0F4;
$yellow: #ea7900;

a{ color: $emerald-green-text; }
button:hover,
a:hover{ color: $bibit-green; }
body{ color: $black; }

//colors for Wordpress
.site-contents, .editor-writing-flow{
  //background
  .has-pale-pink-background-color { background-color: $pale-pink; }
  .has-vivid-red-background-color { background-color: $red; }
  .has-luminous-vivid-orange-background-color { background-color: $magenta; }
  .has-luminous-vivid-amber-background-color { background-color: $yellow; }
  .has-light-green-cyan-background-color { background-color: $light-green; }
  .has-vivid-green-cyan-background-color { background-color: $emerald-green; }
  .has-pale-cyan-blue-background-color { background-color: $cyan; }
  .has-vivid-cyan-blue-background-color { background-color: $blue; }
  .has-very-light-gray-background-color { background-color: $white; }
  .has-cyan-bluish-gray-background-color { background-color: $light-gray; }
  .has-very-dark-gray-background-color { background-color: $gray; }

  //text
  .has-pale-pink-color { color: $pale-pink; }
  .has-vivid-red-color { color: $red; }
  .has-luminous-vivid-orange-color { color: $magenta; }
  .has-luminous-vivid-amber-color { color: $yellow; }
  .has-light-green-cyan-color { color: $bibit-green; }
  .has-vivid-green-cyan-color { color: $emerald-green-text; }
  .has-pale-cyan-blue-color { color: $cyan; }
  .has-vivid-cyan-blue-color { color: $blue; }
  .has-very-light-gray-color { color: $white; }
  .has-cyan-bluish-gray-color { color: $light-gray; }
  .has-very-dark-gray-color { color: $gray; }
}
