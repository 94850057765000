$small-padding-bottom: 40em;

.site-footer{
  position: fixed;
  left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%; height: 32em;
  padding: 2em 4em 0;
  background-color: $pale-gray;
  @include breakpoint(small down){
    height: $small-padding-bottom;
    padding: 2em 1.5em 0;
  };
  a{
    transition: color 0.5s ease(out-quint);
    text-decoration: underline;
  }
}

.footer-contact{
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  &__title{
    @extend .has-normal-font-size;
    margin-bottom: 0.5em;
  }
  dl{ margin: 0; }
  dd{ margin: 0; }
  @include breakpoint( medium down){
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    width: auto;
  };
  &__copyright, &__twitter{
    margin-left: 0.5em;
    @include breakpoint( medium down){
      align-self: flex-end;
      margin-left: 0;
    };
  }
  &__copyright{
    @extend %en-font;
    margin-top: 1em;
  }
  &__twitter{
    line-height: 1;
    font-size: rem-calc(30);
    a{
      display: block;
      padding: 0 0.25em;
      text-decoration: none;
      color: $twitter-blue;
    }
  }
}

.EOF{
  order: 3;
  align-self: center;
  @include breakpoint(small down){
    margin-right: -1.5em;
    align-self: flex-end;
  };
  a, &__logo{
    display: inline-block;
    padding: 1em;
    line-height: 23px;
    font-size: 30px;
    color: $light-gray;
  }
}
